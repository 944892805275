
import * as React from 'react';
import FormField from "../../../../Components/common-components/form-input"
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import AddIcon from '@mui/icons-material/Add';
import TableHeadings from "../../../../Components/common-components/table-headings"
import { Switch } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "../../../../Components/PopUpModal";
import Pagination from "../../../../Components/Pagination"
import useTemplateCategory from '../Hooks/useTemplateCategory';
import { Spinner } from "../../../../Components/common-components/spinner"
import { getTableHead, Permission } from '../../../../Utils';
import { Permission_Message } from '../../../../Components/common-components/permissionMessage';


function TemplateCategory() {
    const { methods, data } = useTemplateCategory()
    const { spinner, categoryModal, totalCount, controller, categoryList, deleteModal,
        filter,
        // ------form-------
        categoryInputs,
        categoryError,

    } = data
    const { setCategoryModal,
        setDeleteModal,
        setController,
        status,
        setFilter,
        ApplyFilter,
        resetFilter,
        // -------Form-------
        setCategoryInputs,
        handleInputChange,
        handleBlur,
        handleAddSubmit,
        handleupdateSubmit,
        handleDelete,
        clearForm,
    } = methods

    const TableHeadData = [
        { label: '#' },
        { label: 'Form Category' },
        { label: 'Status' },
        { label: 'Action' },
    ]
    const Add_Permission = Permission({ permission: 'Template Library category-Able to add category' })
    const Edit_Permission = Permission({ permission: 'Template Library category-Able to edit category' })
    const View_Permission = Permission({ permission: 'Template Library category-Able to View/List category' })
    const Delete_Permission = Permission({ permission: 'Template Library category-Able to Delete category' })
    const Archive_UnArchive_Permission = Permission({ permission: 'Template Library category-Able to Active/In-Active category' })
    const tableHeadData = getTableHead(Edit_Permission, Delete_Permission, TableHeadData);

    return (

        <div className='p-2'>
            {spinner && <Spinner />}
            <div className="d-lg-flex align-items-center">
                <div className='mx-2 mx-sm-0'>
                    <FormField data={{ placeholder: 'Search', rightIcon: '', type: 'text' }} value={filter?.search} onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))} />
                </div>
                <div className="d-flex mt-3 ms-lg-3 ms-sm-0 ms-2">
                    <button className="theme-btn bgDarkBlue" onClick={ApplyFilter}> <SearchIcon style={{ height: "20px" }} /> Search</button>
                    <button className="theme-btn bgDarkBlue mx-3" onClick={resetFilter}> <RestartAltIcon style={{ height: "20px" }} /> Reset</button>
                    {Add_Permission && <button className="theme-btn bgLightGreen" onClick={() => { setCategoryModal((prev) => ({ ...prev, open: true })) }}> <AddIcon style={{ height: "20px" }} /> Add </button>}
                </div>
            </div>
            {
                View_Permission?
            <div>
                <div className="mx-2 mx-sm-0 mt-3 pb-3 overflowX">
                    <table className="customTable">
                        <TableHeadings TableHeadData={tableHeadData} />
                        <tbody>
                            {categoryList?.map((cur, index) =>
                                <tr key={index}>
                                    <>
                                        <td> {controller.rowsPerPage * (controller.page - 1) + index + 1} </td>
                                        <td>{cur?.name}</td>
                                        <td  >
                                            <Switch checked={cur?.isActive} title='Status Active/In-active'
                                                onChange={(e) => status(e, cur?.id)} disabled={Archive_UnArchive_Permission ? false : true}
                                            />
                                        </td>
                                        {
                                            (Delete_Permission || Edit_Permission) &&
                                            <td>
                                                <div className='d-flex'>
                                                    {
                                                        Delete_Permission &&
                                                        <DeleteIcon className='cursor red' titleAccess='Delete'
                                                            onClick={() => setDeleteModal((prev) => ({ ...prev, data: cur, open: true }))}
                                                        />
                                                    }
                                                    {
                                                        Edit_Permission &&
                                                        <CreateIcon className='cursor' color="primary" titleAccess='Edit'
                                                            onClick={() => {
                                                                setCategoryInputs({
                                                                    name: cur?.name,
                                                                });
                                                                setCategoryModal((prev) => ({ ...prev, data: { id: cur?.id, isActive: cur?.isActive }, open: true }))
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                        }

                                    </>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {!categoryList?.length && <div className="text-center mt-3">No record found</div>}
                    <div className="d-flex justify-content-center mt-2">
                        {
                            totalCount > 10 && <Pagination count={totalCount} controller={controller} setController={setController} />
                        }
                    </div>
                </div>
            </div>
            : Permission_Message
            }


            {/* Form Modal add appointment type */}
            <Modal className="p-0"
                size="lg" show={categoryModal?.open} onHide={() => {
                    clearForm()
                    setCategoryModal({ data: null, open: false })
                }}
                headerContent={<h5 className="py-2 my-1 ms-2"> {`${categoryModal?.data ? "Edit" : "Add"}`} Category</h5>}
                bodyContent={
                    <div >
                        <div className="row px-4 pb-4 m-2">
                            <div className="col-lg-4  col-sm-8 col-12">
                                <FormField
                                    name={'name'}
                                    value={categoryInputs?.name}
                                    onBlur={(e) => handleBlur(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    error={categoryError?.name}
                                    data={{ label: 'Name', placeholder: '', type: 'text' }} />
                            </div>

                        </div>

                        <div className="d-flex justify-content-end border-top p-4">
                            <div>
                                <button type="submit" className="theme-btn bgLightGray col-11 px-md-5" onClick={() => {
                                    clearForm()
                                    setCategoryModal({ data: null, open: false })
                                }}> Discard</button>
                            </div>
                            <div>
                                {categoryModal?.data ? <button type="button" className="theme-btn bgDarkBlue col-11 px-md-5" onClick={handleupdateSubmit}> Update</button>
                                    :
                                    <button type="button" className="theme-btn bgDarkBlue col-11 ms-2 px-md-5" onClick={handleAddSubmit}> Save</button>
                                }
                            </div>
                        </div>
                    </div>
                }
            />
            {/* delete Modal of appointment type */}
            <Modal
                size="md" headerBorderBottom={true} show={deleteModal?.open} onHide={() => { setDeleteModal({ data: null, open: false }) }}
                bodyContent={
                    <div >
                        {/* <div className="row"> */}
                        <div className="text-center col-12">
                            <div className='mt-3 mb-4'>
                                <DeleteIcon className='cursor red' sx={{ fontSize: '50px' }} />
                            </div>
                            <div className="fw-7 font-22">Delete Category</div >
                            <div className="text-body-secondary font-13 my-2">
                                You are about to delete this category <span className='black1 fw-7 font-12'>{deleteModal?.data?.name}</span> <br /> Are you sure?
                            </div>
                        </div>
                        {/* </div> */}
                        <div className="d-flex justify-content-center mt-3 mb-4">
                            <button type="button" className="theme-btn bg-danger col-5" onClick={handleDelete}> Delete</button>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default TemplateCategory