
import { axiosInstance, baseUrl, handleErrors } from "./auth";



export const PERIO_CHART = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/perioChart`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const PERIO_CHART_UPDATE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/perioChart`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const REMOVE_TEETH = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/perioChart/listRemoveTeeth`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


export const TIME_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/perioChart/timeList`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const CHART_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/perioChart/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const AUTO_SAVE_TOGGLE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/perioChart/toggleAutoSave`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const GET_AUTO_SAVE_STATUS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/perioChart/activeStatus  `, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const TREATMENT_ADD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const TREATMENT_UPDATE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/treatmentplan`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


export const TREATMENT_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/List`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const RESTORATIVETREATMENT_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeChart/ListRestorativeChart`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const TREATMENT_LIST_WITHOUTFILTER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeChart/List`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// uploadTreatmentPlanImage
export const RESTORATIVE_CHART_SCREENSHOOT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeChart/uploadTreatmentPlanImage`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const TREATMENT_FULL_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/List`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const TREATMENTPLANID_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/tpIdList`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// /treatmentplan/updateIds
export const TREATMENTPLAN_UPDATE_IDS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/updateIds`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// /treatmentplan/changeStatus
export const TREATMENTPLAN_CHANGE_STATUS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/changeStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const TREATMENTPLAN_DISCOUNT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/discountPercent`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


 

export const PHASE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/phaseIdList`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const PROVIDER_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/provider/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const ORDER_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/orderIdList`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    treatment  details
export const TREATMENT_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/treatmentplan/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//    treatment  attachments
export const TREATMENT_ATTACHMENTS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/attachments`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//    treatment  attachments
export const UPLOADTREATMENTATTACHMENT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/upload`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const TREATMENT_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}



// E-prescription

export const PRESCRIPTION_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/prescription/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const ADD_PRESCRIPTION = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/prescription/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const PRESCRIPTION_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/prescription/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const DELETE_PRESCRIPTION = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/prescription/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// Patient notes


export const PATIENT_NOTES_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patientNotes/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const ADD_PATIENT_NOTES = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patientNotes/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const PATIENT_NOTES_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patientNotes/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const DELETE_PATIENT_NOTES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/patientNotes/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const UPDATE_PATIENT_NOTES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/patientNotes/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// progress notes 

// progress notes delete 
export const PROGRESSNOTES_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/progressNotes/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
