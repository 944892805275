import Modal from 'react-bootstrap/Modal';
import React from 'react'

const index = ({ show, onHide, headerContent, bodyContent, size, CloseFucntion, HeaderClass, className, headerBorderBottom, backdrop }) => {
    return (
        <Modal size={size} show={show} onHide={onHide} centered backdrop={backdrop}>
            {
                headerContent &&
                <Modal.Header className={HeaderClass ? HeaderClass : 'py-3'} style={{ borderBottom: headerBorderBottom ? "none" : "1px solid #D5DBE4" }}  onClick={CloseFucntion}>
                    {headerContent}
                </Modal.Header>
            }
            <Modal.Body className={className}>{bodyContent}</Modal.Body>
        </Modal>
    )
}

export default index