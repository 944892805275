import store from "../Store/index";
export default function authHeader() {
    const res = store.getState()?.auth
    const responseTowfactoe = store.getState()?.TowFactor
    // console.log(responseTowfactoe?.token,"responseTowfactoe?.token")
    if (res?.token || responseTowfactoe?.token) {
        return { Authorization: 'Bearer ' + (res?.token || responseTowfactoe?.token), refreshToken: (res?.refreshToken || responseTowfactoe?.refreshToken) };
    } else {
        return {};
    }
}