import useReduxStore from "../hooks/useReduxStore"
const useAuthUser = () => {
  const { selector: user } = useReduxStore("auth");
  return user;
};

// this function return required error'sand required select.
export const requiredMessage = (name) => {
  return `Please enter ${name}`
}
export const requiredSelect = (name) => {
  return `Please select ${name}`
}
export const requiredDate = (name) => {
  return `Please choose ${name}`
}

// This function is used to automatically scroll the page to the location where an error occurs.
export const scrollIntoViewHelper = (errors) => {
  const firstError = Object.keys(errors)[0];
  let el = document.querySelector(`[name="${firstError}"]`);
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};

// Function to remove HTML tags from a string
export const removeHtmlTags = (htmlString) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
};

export function calculateWorkHours(duration) {
  const hours = Math.floor(duration.asHours());
  const remainingMinutes = duration.minutes();
  let result = '';

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  if (remainingMinutes > 0) {
    result += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
  }
  return result;
}

export function capitalize(val) {
  return val[0]?.toUpperCase() + val?.slice(1);
}

export function hideEmail(email) {
  const atIndex = email?.indexOf('@');
  if (atIndex > 1) {
    const visiblePart = email?.substring(0, 2);
    const hiddenPart = '*'.repeat(atIndex - 2);
    const domainPart = email?.substring(atIndex);
    return visiblePart + hiddenPart + domainPart;
  } else {
    return email;
  }
}

// export const Permission = ({ permission, userData }) => {
//   let active = false
//   if (userData?.Role !== 'END_USER') {
//     let Permission = userData?.permissions?.find((cur, index) => cur?.toLowerCase() === permission?.toLowerCase())
//     if (Permission) {
//       active = true
//     }
//   }
//   else if (userData?.Role === 'END_USER') {
//     active = true
//   }
//   else {
//     active = false
//   }
//   return active
// }



// export const Permission = ({ permission }) => {
//   let active = false
//   const user = useAuthUser()
//   let Permission = user?.permissions?.find((cur, index) => cur?.toLowerCase() === permission?.toLowerCase())
//   if (Permission) {
//     active = true;
//   }
//   else {
//     active = false;
//   }
//   // console.log(active,'active')
//   return active
// }


export const Permission = ({ permission }) => {
  const user = useAuthUser();
  const permissions = user?.permissions || [];

  const active = permissions.some((cur) => {
    return typeof cur === 'string' && cur.toLowerCase() === permission?.toLowerCase();
  });

  return active;
};


export const getTableHead = (Edit_Permission, Delete_Permission, TableHeadDataTableHeadData) => {
  if (!Edit_Permission && !Delete_Permission) {
    return TableHeadDataTableHeadData.filter(item => item.label !== 'Action');
  }
  return TableHeadDataTableHeadData;
};
export const getTimeZoneAndOffset = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneOffset = new Date().getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(timeZoneOffset) / 60);
  const offsetMinutes = Math.abs(timeZoneOffset) % 60;
  const offsetSign = timeZoneOffset > 0 ? "-" : "+";
  const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

  return {
    timeZone,
    offset: formattedOffset
  };
};
export function calculateAge(birthdate = null) {
  const birthDate = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If the current month is before the birth month or the current day is before the birth day,
  // subtract one year from the age
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

// 
export const handalDOB = (value = null) => {
  let today = new Date();
  let birthDate = new Date(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age || "--"
}

// debounce text
export function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

// OTP Only number validator
export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = typeof text === 'string' && text.trim() !== '';
  return (isNumber || isString) && !isNaN(Number(text));
}

// Function to determine if the file is an image or a PDF
export const isImage = (fileName) => {
  return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
};

export const isPDF = (fileName) => {
  return /\.pdf$/i.test(fileName);
};
export const FormatGender = (value = null) => {
  let Gender;
  if (value === "M") {
    Gender = "Male"
  }
  if (value === "F") {
    Gender = "Female"
  }
  if (value === "O") {
    Gender = "Other"
  }

  return Gender || "--"
}

export const truncateText = (text, maxLength) => {
  const capitalizeText = text?.replace(/\b\w/g, (char) => char.toUpperCase());
  if (capitalizeText.length > maxLength) {
    return capitalizeText.substring(0, maxLength) + '...';
  }
  return capitalizeText;
};

// Pagination handler function
export const adjustPageAfterDeletion = ({ totalCount, currentPage, rowsPerPage, onPageChange, onTotalCountChange }) => {
  const newTotalItems = totalCount - 1;

  // Calculate the new total pages after deletion
  const newTotalPages = Math.ceil(newTotalItems / rowsPerPage);

  // If the new total pages are less than the current page, decrement the page
  if (currentPage > newTotalPages && newTotalPages > 0) {
    if (typeof onPageChange === 'function') {
      onPageChange(newTotalPages);
    }
  }

  // Update the total count if the callback is provided
  if (typeof onTotalCountChange === 'function') {
    onTotalCountChange(newTotalItems);
  }
};


//  Add space after every 4 digits
export const formatCardNumber = (inputValue) => {
  return inputValue.replace(/\s+/g, '') // Remove spaces
    .replace(/(\d{4})(?=\d)/g, '$1 ') // Add space after every 4 digits
    .trim(); // Trim trailing spaces
};