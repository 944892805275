import React, { Suspense, useContext, useEffect } from 'react'
import PatientHeader from './patientHeader'
import { Outlet } from 'react-router-dom'
import useHeader from '../Hooks/useHeader'
import { NotificationsState } from '../../../App'
import useReduxStore from '../../../hooks/useReduxStore'
import { environment } from '../../../env/environment'

const PatientNavigationLayout = () => {

    const { setSocket } = useContext(NotificationsState)
    const { selector: user } = useReduxStore("auth")

    const { data, methods, } = useHeader()
    const { createAppointmentModal, patientListData } = data
    const { setCreateAppointmentModal } = methods

    useEffect(() => {
        let ws;
        let reconnectInterval;

        const connectWebSocket = () => {
            ws = new WebSocket(`${environment?.socketUrl}/dental/websocket`);
            ws.onopen = () => {
                setSocket(ws);
                const data = {
                    eventType: "register",
                    userId: user?.user_id,
                };
                ws.send(JSON.stringify(data));
            };
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                attemptReconnect();
            };
            ws.onclose = () => {
                console.warn('WebSocket closed, attempting to reconnect...');
                attemptReconnect();
            };
        };

        const attemptReconnect = () => {
            clearInterval(reconnectInterval);
            reconnectInterval = setInterval(() => {
                if (ws.readyState === WebSocket.CLOSED) {
                    // console.log("new socket")
                    // connectWebSocket(); //uncommit
                } else {
                    clearInterval(reconnectInterval);
                }
            }, 5000); // Try to reconnect every 5 seconds
        };

        // connectWebSocket(); //uncommit

        return () => {
            clearInterval(reconnectInterval);
            if (ws) {
                ws.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="navigation-layout">
            {/* {spinner && <Spinner />} */}

            <PatientHeader />
            <div className="main-content ">
                {/* <div className='MAIN_CONTAINER'> */}
                <div className='h-100 '>
                    {/* <div className={`nested_content ${(location?.pathname === "/appointment_scheduler" || location.pathname === "charting") && "nested_content_AppointmentHeader"}`}> */}
                    <div>
                        {/* <button onClick={() => setCount((prev) => prev + 1)}>Count = {count}</button> */}
                        <Suspense fallback={"Loading..."}>
                            <Outlet context={{ setCreateAppointmentModal, createAppointmentModal, patientListData }} />
                        </Suspense>
                        {/* {count > 5 && <Suspense fallback={"...Loading ooooo"}><ClinicDetails /></Suspense>} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientNavigationLayout
