import React from 'react'
import FormField from "../../Components/common-components/form-input"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Chip, styled, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TableHeadings from '../../Components/common-components/table-headings';
import DesktopDateTimePicker from '../../Components/DesktopDateTimePicker'
import MultipleSelect from "../../Components/MultipleSelect"
import moment from 'moment-timezone';
import { Checkbox } from '@mui/material';
import Slider from '@mui/material/Slider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const AddEditAppointment = ({ data, methods }) => {
    const { providerList, HygienistList, appointmentTypeList, patientListData, operatoriesList, appointmentInput, appointmentError, createAppointmentModal,
        listData, selectedProcedureCode, GroupTeatmentPlanList, treatmentPlanData } = data
    const { setCreateAppointmentModal, handleInputChange, setSelectProcedureModal, handleAppointmentBook, clearForm, setSelectedProcedureCode,
        getProcedureCategoryList, getProcedureList, handleChangeProcedure, handleChangeServices } = methods

    // const TableHeadData = [
    //     { label: '#' },
    //     { label: 'Tooth' },
    //     { label: 'Surface' },
    //     { label: 'Procedure Code' },
    //     { label: 'Description' },
    //     { label: 'Priority' },
    //     { label: 'Fee' },
    //     { label: 'Insurance Allowed Amount' },
    //     { label: 'Primary Insurance' },
    //     { label: 'Secondary Insurance' },
    //     { label: 'Discount Amount' },
    //     { label: 'Patient Responsible' },

    // ]
    const TableHeadData = [
        { label: '' },
        { label: '#' },
        { label: 'Date' },
        { label: 'Tooth' },
        { label: 'Surface' },
        { label: 'Procedure Code' },
        { label: 'Description' },
        { label: 'TPID' },
        { label: 'OID' },
        { label: 'PID' },
        { label: 'Fee' },
        // { label: 'Action' },
    ]
    const formatDuration = (value) => {
        if (value >= 60) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
        } else {
            return `${value} minute`;
        }
    };
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    console.log(treatmentPlanData, 'TreatmentPlanData')
    console.log(GroupTeatmentPlanList, "GroupTeatmentPlanList")
    return (
        <div className='p-4'>
            <div className="row">
                <div className="col-12">
                    <div className='d-flex justify-content-center shadow-sm bgBlue2 font-14 fw-5 text-white p-2'>Appointment Details</div>
                    <div className='row'>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={true}
                                label={"Patient Name"}
                                multiple={false}
                                options={patientListData}
                                searchBy={"fullName"}
                                keyName={"fullName"}
                                name={"patientId"}
                                onChange={(e) => handleInputChange({ target: { name: "patientId", value: e } })}
                                value={"id"}
                                selectedvalues={appointmentInput?.patientId}
                                error={appointmentError.patientId}
                            />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={true}
                                label={"Operatories"}
                                multiple={false}
                                options={operatoriesList}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"operatoryId"}
                                onChange={(e) => handleInputChange({ target: { name: "operatoryId", value: e } })}
                                value={"id"}
                                selectedvalues={appointmentInput?.operatoryId}
                                error={appointmentError.operatoryId}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={true}
                                label={"Provider Name"}
                                multiple={false}
                                options={providerList}
                                searchBy={"fullName"}
                                keyName={"fullName"}
                                name={"providerId"}
                                onChange={(e) => handleInputChange({ target: { name: "providerId", value: e } })}
                                value={"id"}
                                selectedvalues={appointmentInput?.providerId}
                                error={appointmentError.providerId}
                            />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                label={"Hygienist"}
                                multiple={false}
                                options={HygienistList}
                                searchBy={"fullName"}
                                keyName={"fullName"}
                                name={"hygienistId"}
                                onChange={(e) => handleInputChange({ target: { name: "hygienistId", value: e } })}
                                value={"id"}
                                selectedvalues={appointmentInput?.hygienistId}
                                error={appointmentError.hygienistId}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={true}
                                label={"Appointment Type"}
                                multiple={false}
                                options={appointmentTypeList}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"appointmentTypeId"}
                                onChange={(e) => handleInputChange({ target: { name: "appointmentTypeId", value: e } })}
                                value={"id"}
                                selectedvalues={appointmentInput?.appointmentTypeId}
                                error={appointmentError.appointmentTypeId}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 mt-3">
                            <label className={'fw-6 font-13 p-0'} >Duration</label>
                            <Slider
                                value={appointmentInput?.duration}
                                onChange={(e, newValue) => handleInputChange({ target: { name: "duration", value: newValue } })}
                                valueLabelDisplay="auto"
                                step={10}
                                min={10}
                                max={700}
                                valueLabelFormat={(value) => formatDuration(value)}
                            />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 mt-3 ">
                            <DesktopDateTimePicker
                                className="w-100 form-field-design"
                                isRequired={true}
                                Label={'Date/Time'}
                                handleDateChange={(e) => {
                                    handleInputChange({ target: { name: "startDateTime", value: e } })
                                }}
                                SelectedDate={appointmentInput?.startDateTime || null}
                                Error={appointmentError?.startDateTime}
                            />
                        </div>
                        <div className="col-lg-5 col-sm-6 col-12 ">
                            <FormField
                                // required={true}
                                name={'notes'}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                data={{ label: 'Add Notes', placeholder: '', type: 'text' }}
                                error={appointmentError?.notes}
                                value={appointmentInput?.notes}
                            />
                        </div>

                        {
                            createAppointmentModal?.type === 'CREATE' &&
                            <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                <div className='mt-sm-4 pt-sm-2'>
                                    <Switch checked={appointmentInput?.isWating}
                                        onChange={(e) => handleInputChange({ target: { name: "isWating", value: e?.target?.checked } })}
                                    /> <span className='font-13 fw-6'> Add to Waiting List</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {
                    treatmentPlanData?.length === 0 ? <div className="col-12 mt-4">
                        <div className='d-flex justify-content-center shadow-sm bgBlue2 font-14 fw-5 text-white p-2'>Procedure</div>
                        <div className='row'>
                            <div className='mb-3 d-flex mt-3'>
                                <button className='theme-btn bgLightGreen2' onClick={() => { setSelectProcedureModal({ open: true, data: null }); getProcedureCategoryList(); getProcedureList(); setSelectedProcedureCode(appointmentInput?.procedureIds || []) }}><AddIcon className='font-18' />  Add New </button>
                            </div>
                            <table className="customTableTeamManagment">
                                <TableHeadings className={'p-2  bgGrey2'} TableHeadData={TableHeadData} />
                                <tbody>
                                    {
                                        listData?.length > 0 && listData?.map((item, index) =>
                                            <tr key={index} >
                                                <td>
                                                    <Checkbox checked={selectedProcedureCode.some(selectedItem => selectedItem?.id === item?.id)} onChange={(e) => handleChangeProcedure(e, item)} />
                                                </td>
                                                <td> {index + 1}</td>
                                                <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                                                <td>{item?.toothNo}</td>
                                                <td>
                                                    {item?.surfaceObjects?.map((data, index) =>
                                                        `${data?.value} `
                                                    )}
                                                </td>
                                                <td>{item?.adaCode ? item?.adaCode?.code : '--'}</td>
                                                <td>{item?.adaCode ? item?.adaCode?.description : '--'}</td>
                                                <td>{item?.tPlanId}</td>
                                                <td>{item?.orderId}</td>
                                                <td>{item?.phaseId}</td>
                                                <td>{item?.fee ? item?.fee : '--'}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className="col-12 mt-4">
                        <div className='d-flex justify-content-center shadow-sm bgBlue2 font-14 fw-5 text-white p-2'>Procedure</div>
                        <div className='row m-0 mt-3'>

                            {
                                GroupTeatmentPlanList?.map((cur) => {
                                    return <Accordion key={cur?.tPlanId} expanded={expanded === `panel${cur?.tPlanId}`} onChange={handleChange(`panel${cur?.tPlanId}`)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${cur?.tPlanId}bh-content`}
                                            id={`panel${cur?.tPlanId}bh-header`}
                                        >
                                            <div className='d-flex justify-content-between w-100 align-items-center'>
                                                <h6 className='m-0'>{`Plan ${cur?.tPlanId}`}</h6>

                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                cur?.phases?.map((Phase, phasesIndex) => {
                                                    return <React.Fragment key={phasesIndex}>
                                                        <div className="col-12 px-2 ">
                                                            <p className='font-14 m-0'>{`Phase ${Phase?.phaseId}`}</p>

                                                            <ul className=''>
                                                                {
                                                                    Phase?.teethData?.map((currentTeeth, TeethIndex) => {
                                                                        return <li key={TeethIndex} className='d-flex align-items-center'>
                                                                            <Checkbox checked={currentTeeth?.isChecked} onChange={(e) => handleChangeServices(e.target.checked, cur?.tplanId, Phase?.phaseId, currentTeeth)} id={`exampleInputEmail1${Phase?.phaseId}${currentTeeth?.id}`} size='small' />
                                                                            <label className='cursor' for={`exampleInputEmail1${Phase?.phaseId}${currentTeeth?.id}`}>{`${currentTeeth?.adaCode?.code??""} ${currentTeeth?.adaCode?.abbrev??""} ${currentTeeth?.adaCode?.description??""}`}</label>
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>

                                                        </div>
                                                    </React.Fragment>
                                                })
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            }

                        </div>
                    </div>
                }
                <div className='d-flex mt-4 justify-content-end pt-4'>
                    <div >  <button className='theme-btn bgLightGray px-30' onClick={() => { setCreateAppointmentModal({ open: false, data: null, type: "CREATE" }); clearForm() }}>Discard</button> </div>
                    <div className='ms-3 '>  <button className='theme-btn bgDarkBlue3 px-30' onClick={handleAppointmentBook}>Submit</button> </div>
                </div>
            </div>
        </div>
    )
}

export default AddEditAppointment
