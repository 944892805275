import "./logo.css"
import logoIcon from "../../Assets/images/dentalLogo.png"
import useReduxStore from "../../hooks/useReduxStore";
function Logo() {
    const handleToggleSideBar = () => {
        document.body.classList.toggle('toggle-sidebar');
    };
    const { selector: user } = useReduxStore("auth")


    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className="logo d-flex align-items-center">
                <span className="ms-3">
                    <img src={logoIcon} alt="logo" />
                </span>
                <span className="d-none d-lg-block ms-2 font-12 fw-7" >{`I Cloud Dental ${user?.roleName === "PATIENT" ? '' : '(Clinic Admin)'}`}</span>
            </div>
            <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSideBar}></i>
        </div>
    );
}

export default Logo;
