import React from 'react'
import Select from 'react-dropdown-select';
import "./selectStyle.css"
const index = ({
    label = "",
    error = "",
    isRequired = false,
    value,
    onChange,
    options=[],
    name,
    keyName,
    multiple= false,
    disabled = false,
    searchBy,
    selectedvalues,
    placeholder = ""
}) => {
    return (
        <div className={"mt-3"}>
            {
                label && <label className={'fw-6 font-11 p-0'} >{label} {isRequired && <span className="text-danger"> *</span>}</label>
            }
            <Select
                dropdownPosition="auto"
                required={isRequired}
                searchable={true}
                multi={multiple}
                options={options}
                onChange={onChange}
                searchBy={searchBy}
                labelField={keyName}
                valueField={value}
                name={name}
                className='font-11'
                disabled={disabled}
                values={Array.isArray(selectedvalues) ? selectedvalues : []}
                placeholder={placeholder}
            />
            {error && <p className="font-11" style={{ color: 'red' }}>{error}</p>}
        </div>
    )
}

export default index