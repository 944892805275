/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */


export const Validation = {
  pattern: {
    NAME: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
    NAMENumber: /^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
    CLAIMNUMBER: /^[ A-Za-z0-9]*$/,
    REPORTNAME: /^[ A-Za-z]*$/,
    CODE: /^[ A-Za-z_@./#&+-/'/"]*$/,
    DURATION: /^[0-9]{0,3}$/,
    PRICING: /^[0-9.]{0,30}$/,
    CITY: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
    EMAIL: /^(([^<>()\[\]\\.,,:\s@"]+(\.[^<>()\[\]\\.,,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    POSTAL_CODE: /^\d{5}-\d{4}|\d{4}|[A-Z]\d[A-Z] \d[A-Z]\d$/,
    PHONE_NO: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,4}$/,
    FIRM_NUMBER: /^[a-z0-9\-]+$/,
    ALPHANUM: /^[a-zA-Z0-9]+$/,
    MOB_NO: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
    // PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#!%*?&])[A-Za-z\d$@$!%*?&]{6,}/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#!%*?&])[A-Za-z\d$@#$!%*?&]{6,}/,

    DESCRIPTION: /^[ !@#$%^&*()~:;{}?'"=<>A-Za-z0-9_@./#&+-,-]*$/,
    REFNO: /^[ 0-9_@./#&+-,-]*$/,
    TASK_CODE: /^[0-9999]{1,4}$/,
    SUB_DOMAIN: /^[/a-z/A-Z][a-zA-Z0-9-]*[^/-/./0-9]$/,
    PHONE_NO_MASK: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    IVR_ACTION_KEY: /^[0-9]*$/,
    IVR_NUMBER: /^[1-9]*$/,
    RADIUS: /^[0-9]*(?:.)([0-9])+$/,
    LATLONG: /^\s*(\-?\d+(\.\d+)?)$/,
    SSN: /^((\d{3}-?\d{2}-?\d{4})|(X{3}-?X{2}-?X{4}))$/,
    SSN_MASK: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    PRACTICE_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
    USERNAME: /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){1,14}[a-zA-Z0-9]$/,
    USERNAME_MIN_SIZ: /^[a-zA-Z0-9_](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9_]){4,18}[a-zA-Z0-9_]$/,
    WICARE_USERNAME: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{1,}/,
    YEAR_MASK: /d{4}/,
    DECIMAL: /\d+(\.\d{1,2})?/,
    NUMBERnDECIMAL: '^\\d+(\\.\\d+)?$',
    MAXLENGTH: 50,
    MINLENGTH: 3,
    ONLYCHARATERS: /^[aA-zZ\s]+$/,
    ONLYDIGITS: /^[0-9]+$/,
    PASSWORDMINLENGTH: 6,
    PASSWORDMAXLENGTH: 15,
    MINIMUMVACANCY: 1,
    MAXIMUMVACANCY: 2,
    WHITESPACE: /^(?![\s-])[\w\s-]+$/,
    BACKSPACE: /^((?!\s{2,}).)*$/,
    ALPHANUMER: /^[a-zA-Z0-9]+$/,
    // BACKSPACE:.*\\S.*[a-zA-z0-9 ],
    URL: '^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$',
    phoneRegExp: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  }
}

export const patientFormEnums = {
  // Basic Information----------//
  title: "",
  firstName: '',
  middleName: "",
  lastName: '',
  salutation: "",
  preferredPronoun: "",
  gender: "",
  maritalStatus: "",
  dob: "",
  age: "",
  status: "",
  bloodGroup: "",
  isOrthoPatient: false,
  //Address Information------------//
  street: "",
  suiteAptNumber: "",
  region: "",
  country: "",
  state: "",
  city: "",
  zipcode: "",
  // Contact Information-----------//
  preferredContactMethod: null,
  phone: '',
  workPhone: "",
  email: '',
  emergencyContactPersonName: "",
  emergencyContactPersonPhoneNumber: "",
  relationshipWithPatient: "",
  //Financial Information-----------//
  paymentMode: "",
  billingType: "",

  // Reference------------------//
  referenceTo: "",
  referenceBy: "",
  // Other Informantion---------//
  driversLicense: "",
  responsibleParty: "",
  language: "",
  policyHolder: "",
  employmentType: "",
  preferredProvider: "",
  preferredHygienist: "",
}

export const insuranceFromEnums = {
  coverage: "",
  insuranceCompanyId: "",
  issueDate: "",
  insuranceType: "",
  insurancePlanType: null,
  insurancePhone: null,
  payerId: "",
  CpId: "",

  insurance_address1: "",
  insurance_address2: "",
  insuranceRegion: "",
  insurance_Country: null,
  insurance_State: null,
  insurance_City: null,
  insurance_Zipcode: "",

  subscriberFirstName: "",
  subscriberMiddleName: "",
  subscriberLastName: "",
  subscriberGender: null,
  subscriberId: "",
  subscriberDob: null,
  subscriberRelationship: null,

  deductibleIndividual: 0,
  deductibleIndividualRemaining: 0,
  deductibleFamily: 0,
  deductibleFamilyRemaining: 0,
  annualMaxIndividual: 0,
  annualMaxIndividualRemaining: 0,
  annualMaxFamily: 0,
  annualMaxFamilyRemaining: 0,
  orthoIndividual: 0,
  orthoIndividualRemaining: 0,
  orthoFamily: 0,
  orthoFamilyRemaining: 0,


  address1: "",
  address2: "",
  subscriberCountry: null,
  subscriberState: null,
  subscriberCity: null,
  subscriberZipcode: "",
  insuranceCard: null,
  note: "",
}
export const modalEnum = { open: false, data: null }
export const communicationType = [
  {
    value: "EMAIL",
    label: "Email"
  },
  {
    value: "PHONE",
    label: "Phone"
  },
];
export const relationShipArr = [
  { value: 'father', label: 'Father' },
  { value: 'mother', label: 'Mother' },
  { value: 'brother', label: 'Brother' },

]
export const colors = ["red", "blue", "green", "yellow", "orange", "purple", "pink", "cyan", "magenta", "brown"];

export const TitleArr = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Miss', label: 'Miss' },
]

// export const CoverageArr = [
//   { value: 'Primary', label: 'Primary' },
//   { value: 'Secondary', label: 'Secondary' },
//   { value: 'Tertiary', label: 'Tertiary' },

// ]
export const PaymentGatewayOption = [
  { title: "Stripe", value: "STRIPE" },
  // { title: "G-Pay", value: "GPAY" },
]

export const CoverageArr = [
  { title: "Primary", value: "PRIMARY" },
  { title: "Secondary", value: "SECONDARY" },
  { title: "Tertiary", value: "TERTIARY" },
]
// insurance Type 
export const insuranceTypeArr = [
  { title: "HMO", value: "HMO" },
  { title: "PPO", value: "PPO" },
  { title: "EAP", value: "EAP" },
  { title: "EPO", value: "EPO" },
  { title: "Unknown", value: "Unknown" },
]
// insurance Plan Type
export const PlanTypeData = [
  { value: '11', title: 'Other Non-Federal Programs' },
  { value: '12', title: 'Preferred Provider Organization (PPO)' },
  { value: '13', title: 'Point of Service (POS)' },
  { value: '14', title: 'Exclusive Provider Organization (EPO)' },
  { value: '15', title: 'Indemnity Insurance' },
  { value: '16', title: 'Health Maintenance Organization (HMO) Medicare Risk' },
  { value: '17', title: 'Dental Maintenance Organization' },
  { value: 'AM', title: 'Automobile Medical' },
  { value: 'BL', title: 'Blue Cross/Blue Shield' },
  { value: 'CH', title: 'Champus' },
  { value: 'CI', title: 'Commercial' },
  { value: 'DS', title: 'Disability' },
  { value: 'FI', title: 'Federal Employees Program' },
  { value: 'HM', title: 'Health Maintenance Organization' },
  { value: 'LM', title: 'Liability Medical' },
  { value: 'MA', title: 'Medicare Part A' },
  { value: 'MB', title: 'Medicare Part B' },
  { value: 'MC', title: 'Medicaid' },
  { value: 'OF', title: 'Other Federal Program' },
  { value: 'TV', title: 'Title V' },
  { value: 'VA', title: 'Veterans Affairs Plan' },
  { value: 'WC', title: "Workers' Compensation Health Claim" },
  { value: 'ZZ', title: 'Mutually Defined' }
];


export const genderArry = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
]
export const feeScheduleType = [
  { title: 'Normal', value: 'NORMAL' },
  { title: 'CoPay', value: 'COPAY' },
  { title: 'Out of Network', value: 'OUTOFNETWORK' },
  { title: 'FixedBenifit', value: 'FIXEDBENEFIT' },
]
export const CodesOption = [
  { title: 'ICD_9', value: 'ICD_9' },
  { title: 'ICD_10', value: 'ICD_10' },
]
export const ReferralTypeOption = [
  { title: 'Internal', value: 'Internal' },
  { title: 'External', value: 'External' },
]

export const fileTypes = {
  pdf_File_Type: ["application/pdf"],
  Img_Type: [
    "image/jpg",
    "image/jpeg",
    "image/jfif",
    "image/pjpeg",
    "image/pjp",
    "image/png",
    "image/svg",
    "image/webp",
  ],
};
export const permissionsEnum = [
  { title: "Dashboard", view: true, add: "N/A", edit: "N/A", delete: "N/A", all: true },
  { title: "Clinic Management", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Team Management", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Roles & Permission", view: true, add: true, edit: true, delete: true, all: true },
  // { title: "Team Management Roles & Permissions", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Default Master List", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Template Library", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Queries", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Subscription Plans", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Revenue Report", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Settings", view: true, add: true, edit: true, delete: true, all: true },
  { title: "Notification", view: true, add: true, edit: true, delete: true, all: true },
  { title: "End User Roles & Permissions", view: true, add: true, edit: true, delete: true, all: true },
]
export const Progress_Note = [
  { value: "#ACC_CUSTOM_FIELD1#", description: "Account Custom Field 1" },
  { value: "#ACC_CUSTOM_FIELD2#", description: "Account Custom Field 2" },
  { value: "#FIRSTVISIT_DATE#", description: "Firstvisit Date" },
  { value: "#LASTVISIT_DATE#", description: "Lastvisit Date" },
  { value: "#OFFICE_BILLING_PROVIDER_LIC#", description: "Office Insurance Billing Provider License" },
  { value: "#OFFICE_BILLING_PROVIDER_NAME#", description: "Office Insurance Billing Provider Name" },
  { value: "#OFFICE_BILLING_PROVIDER_TAX_ID#", description: "Office Insurance Billing Provider Tax ID" },
  { value: "#OFFICE_CUSTOM_FIELD1#", description: "Office Custom Field 1" },
  { value: "#OFFICE_CUSTOM_FIELD2#", description: "Office Custom Field 2" },
  { value: "#PAT_ADDRESS#", description: "Patient Address" },
  { value: "#PAT_AGE#", description: "Patient Age" },
  { value: "#PAT_BIRTHDATE#", description: "Patient Birthdate" },
  { value: "#PAT_CELLPHONE#", description: "Patient Cell Phone No." },
  { value: "#PAT_CHARTNO#", description: "Patient ChartNo#" },
  { value: "#PAT_CITY#", description: "Patient City" },
];

export const Suffix = [
  { title: 'D.D.S', value: 'D.D.S.' },
  { title: 'D.M.D', value: 'D.M.D' },
  { title: 'M.S.D', value: 'M.S.D' },
  { title: 'M.S', value: 'M.S' },
  { title: 'M.D', value: 'M.D' },
]
export const GenderOptions = [
  { title: "Female", value: "F" },
  { title: "Male", value: "M" },
  { title: "Prefer Not To Say", value: "N" },
]
export const Check = [
  { title: "Yes", value: true },
  { title: "No", value: false },
]
export const preferredPronounOption = [
  { title: "None", value: "None" },
  { title: "He/Him", value: "He/Him" },
  { title: "She/Her", value: "She/Her" },
  { title: "They/Them", value: "They/Them" },
]

export const MaritalStatusOptions = [
  { value: "Married", title: "Married" },
  { value: "Unmarried", title: "Unmarried" },
  { value: "Single", title: "Single" },
  { value: "Widowed", title: "Widowed" },
  { value: "Separated", title: "Separated" },
];
export const DefinitionsOptions = [
  { value: "ADDITION", title: "ADDITION" },
  { value: "REDUCTION", title: "REDUCTION" },
];

export const patientStatusOptions = [
  { value: "Standard", title: "Standard" },
  { value: "Insured", title: "Insured" },
  { value: "Uninsured", title: "Uninsured" },
  { value: "Friends/Family", title: "Friends/Family" },
  { value: "BadDebt-Precollections", title: "Bad Debt - Precollections" },
  { value: "BadDebt-Sent-To-Collections", title: "Bad Debt - Sent to Collections" },
]

export const bloodGroupOptions = [
  { value: "A+", title: "A+" },
  { value: "A-", title: "A-" },
  { value: "B+", title: "B+" },
  { value: "B-", title: "B-" },
  { value: "AB+", title: "AB+" },
  { value: "AB-", title: "AB-" },
  { value: "O+", title: "O+" },
  { value: "O-", title: "O-" },
]
export const intervalTypeArry = [
  { title: 'Day', value: 'day' },
  { title: 'Week', value: 'week' },
  { title: 'Month', value: 'month' },
  { title: 'Year', value: 'year' },
]

export const RegionOptions = [
  { value: "North_america", title: "North america" },
  { value: "South_america", title: "South america" },
  { value: "Asia", title: "Asia" },
  { value: "Europe", title: "Europe" },
  { value: "Africa", title: "Africa" },
  { value: "Australia", title: "Australia" },
  { value: "Middle_East", title: "Middle East" },
  { value: "Antartica", title: "Antartica" },
  { value: "Latin_America", title: "Latin America" },
];


export const treatmentPlanStatus = [
  { title: 'All', value: 'ALL' },
  { title: 'Completed', value: 'COMPLETED' },
  { title: 'Active', value: 'TXPLANS' },
]
export const appointmentStatus = [
  { title: 'Cancelled', value: 'Cancelled' },
  { title: 'Scheduled', value: 'Scheduled' },
  { title: 'Arrived', value: 'Arrived' },
  { title: 'Confirmed', value: 'Confirmed' },
  { title: 'Unconfirmed', value: 'Unconfirmed' },
  { title: 'Message', value: 'Message' },
  { title: 'CheckIn', value: 'CheckIn' },
  { title: 'InRoom', value: 'InRoom' },
  { title: 'Done', value: 'Done' },
]
export const formStatus = [
  { title: 'Shared', value: 'SHARED' },
  { title: 'Filled', value: 'FILLED' },
  { title: 'Rejected', value: 'REJECTED' },
]
export const PaymentOptions = [
  { value: "Check", title: "Check" },
  { value: "Card", title: "Card" },
  { value: "Cash", title: "Cash" },
];
export const RecurrenceNumberOptions = [
  { value: 1, title: "First" },
  { value: 2, title: "Second" },
  { value: 3, title: "Third" },
  { value: 4, title: "Fourth" },
  { value: 5, title: "Fifth" },
];
export const WeekDaysOptions = [
  { value: 0, title: "Monday" },
  { value: 1, title: "Tuesday" },
  { value: 2, title: "Wednesday" },
  { value: 3, title: "Thursday" },
  { value: 4, title: "Friday" },
  { value: 5, title: "Saturday" },
  { value: 6, title: "Sunday" }
]


export const timeArry = [
  { title: '10 Minutes', value: 10 },
  { title: '20 Minutes', value: 20 },
  { title: '30 Minutes', value: 30 },
  { title: '40 Minutes', value: 40 },
  { title: '50 Minutes', value: 50 },
  { title: '60 Minutes', value: 60 },
  { title: '70 Minutes', value: 70 },
  { title: '80 Minutes', value: 80 },
  { title: '90 Minutes', value: 90 },
  { title: '100 Minutes', value: 100 },
  { title: '110 Minutes', value: 110 },
  { title: '120 Minutes', value: 120 },
]
export const daysArry = [
  { title: '1 Days', value: 1 },
  { title: '2 Days', value: 2 },
  { title: '3 Days', value: 3 },
  { title: '4 Days', value: 4 },
  { title: '5 Days', value: 5 },
  { title: '6 Days', value: 6 },
]
export const weeksArry = [
  { title: '1 Weeks', value: 1 },
  { title: '2 Weeks', value: 2 },
  { title: '3 Weeks', value: 3 },
  { title: '4 Weeks', value: 4 },
]
export const APPOINTMENT_STATUS = {
  Block: 'Block',
  Pending: 'Pending',
  Cancelled: 'Cancelled',
  Declined: 'Declined',
  Waiting: 'Waiting',
  Scheduled: 'Scheduled',
  Confirmed: 'Confirmed',
  Unconfirmed: 'Unconfirmed',
  Message: 'Message',
  Arrived: 'Arrived',
  CheckIn: 'CheckIn',
  InRoom: 'InRoom',
  Done: 'Done',
}




export const daysArray = [
  { title: '1 day', value: 1 },
  { title: '2 days', value: 2 },
  { title: '3 days', value: 3 },
  { title: '4 days', value: 4 },
  { title: '5 days', value: 5 },
  { title: '6 days', value: 6 },
  { title: '7 days', value: 7 },
  { title: '8 days', value: 8 },
  { title: '9 days', value: 9 },
  { title: '10 days', value: 10 },
  { title: '11 days', value: 11 },
  { title: '12 days', value: 12 },
  { title: '13 days', value: 13 },
  { title: '14 days', value: 14 },
  { title: '15 days', value: 15 },
  { title: '16 days', value: 16 },
  { title: '17 days', value: 17 },
  { title: '18 days', value: 18 },
  { title: '19 days', value: 19 },
  { title: '20 days', value: 20 },
  { title: '21 days', value: 21 },
  { title: '22 days', value: 22 },
  { title: '23 days', value: 23 },
  { title: '24 days', value: 24 },
  { title: '25 days', value: 25 },
  { title: '26 days', value: 26 },
  { title: '27 days', value: 27 },
  { title: '28 days', value: 28 },
  { title: '29 days', value: 29 },
  { title: '30 days', value: 30 },
];

export const MaxAutoReminders = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '3', value: 3 },
  { title: '4', value: 4 },
  { title: '5', value: 5 },
  { title: '6', value: 6 },
  { title: '7', value: 7 },
  { title: '8', value: 8 },
  { title: '9', value: 9 },
  { title: '10', value: 10 },
]

export const medicationStrength = [
  { title: 'Mg', value: 'Mg' },
  { title: 'Ml', value: 'Ml' },
  { title: 'mg/mL', value: 'mg/mL' },
  { title: '%', value: 'Percentage' },
  { title: 'mcg', value: 'mcg' },
  { title: 'ng', value: 'ng' }
]
export const medicationForm = [
  { title: 'Tablet', value: 'tablet' },
  { title: 'Capsules', value: 'capsules' },
  { title: 'Liquid', value: 'liquid' },
  { title: 'Powder', value: 'powder' },
  { title: 'Injectables', value: 'injectables' },
]
export const projectId = '6548d0cc43f4e791d06cc4ab'

export let endUserAllPermissionData = [
  "Profile-Able to view profile",
  "Profile-Able to edit profile",
  "Multiple Locations-Able to view multiple locations",
  "Multiple Locations-Able to edit multiple locations details",
  "Multiple Locations-Able to raise query to add a new location",
  "Notifications-Able to view notifications",
  "Notifications-Able to delete notifications",
  "Schedular-Able to book appointment",
  "Schedular-Able to view appointment",
  "Schedular-Able to edit Appointment",
  "Schedular-Able to cancel appointment",
  "Schedular-Able to re-schedule appointment",
  "Schedular-Able to change appointment status",
  "Schedular-Able to delete appointment",
  "Schedular-Able to cut and paste appointment",
  "Schedular-Able to view appointment request",
  "Schedular-Able to delete appointment request",
  "Waiting List-Able to view waiting list",
  "Waiting List-Able to add appointment from waiting list",
  "Waiting List-Able to delete appointment from waiting list",
  "Patient Management-Able to search patient",
  "Patient Management-Able to view patient",
  "Patient Management-Able to view family member",
  "Patient Management-Able to add patient",
  "Patient Management-Able to add family member",
  "Patient Management-Able to edit patient",
  "Patient Management-Able to edit family member",
  "Patient Management-Able to merge duplicate account",
  "Patient Management-Able to merge as a family",
  "Patient Management-Able to separate account",
  "Medical History-Able to view medical history",
  "Medical History-Able to add medical history",
  "Medical History-Able to edit medical history",
  "Insurance Details-Able to add insurance details",
  "Insurance Details-Able to edit insurance details",
  "Insurance Details-Able to view insurance details",
  "E-prescription-Able to add e-prescription",
  "E-prescription-Able to view e-prescription",
  "E-prescription-Able to edit e-prescription",
  "Treatment Plan-Able to add treatment plan",
  "Treatment Plan-Able to edit treatment plan",
  "Treatment Plan-Able to view treatment plan",
  "Treatment Plan-Able to change status of treatment plan",
  "Progress Notes-Able to add progress notes",
  "Progress Notes-Able to edit progress notes Within 24 hours of adding note",
  "Progress Notes-Able to view progress notes",
  "Clinic Notes-Able to add clinical Notes",
  "Clinic Notes-Able to edit clinical Notes",
  "Clinic Notes-Able to view clinical Notes",
  "Team Management-Able to search team member",
  "Team Management-Able to view team member",
  "Team Management-Able to add team member",
  "Team Management-Able to edit team member",
  "Team Management-Able to view availability",
  "Team Management-Able to Un-availability",
  "Team Management-Able to view lunch time",
  "Team Management-Able to add availability",
  "Team Management-Able to edit availability",
  "Team Management-Able to add un-availability",
  "Team Management-Able to edit un-availability",
  "Team Management-Able to delete un-availability",
  "Team Management-Able to add lunch time",
  "Team Management-Able to edit lunch time",
  "Team Management-Able to add appointment during lunch",
  "Team Management-Able to assign locations",
  "Team Management-Able to view locations",
  "Team Management-Able to edit locations",
  "Team Management-Able to assign Procedure Codes",
  "Team Management-Able to view assigned procedure codes",
  "Team Management-Able to remove assigned procedure codes",
  "Inbox-Able to chat with patient",
  "Inbox-Able to chat with team members",
  "Inbox-Able to create channels chats",
  "Inbox-Able to edit channels",
  "Inbox-Able to create broadcast",
  "Inbox-Able to edit broadcast list",
  "Charting-Restorative Charting-Able to add restorative chart",
  "Charting-Restorative Charting-Able to view restorative chart",
  "Charting-Restorative Charting-Able to edit Restorative chart",
  "Charting-Perio Charting-Able to add perio chart",
  "Charting-Perio Charting-Able to view perio chart",
  "Charting-Perio Charting-Able to edit Perio Chart",
  "Charting-Ortho Charting-Able to add ortho chart",
  "Charting-Ortho Charting-Able to view ortho chart",
  "Charting-Ortho Charting-Able to view ortho chart",
  "Setup-Able to add master list in setup",
  "Setup-Able to delete master list in setup",
  "Setup-Able to edit master list in setup",
  "Setup-Able to view master list",
  "Setup-Procedure Code Type-Able to add Procedure Code Type",
  "Setup-Procedure Code Type-Able to edit Procedure Code Type",
  "Setup-Procedure Code Type-Able to View/List Procedure Code Type",
  "Setup-Procedure Code Type-Able to Delete Procedure Code Type",
  "Setup-Procedure Code Type-Able to Active/In-Active Procedure Code Type",
  "Setup-Procedure Code Category-Able to add Procedure Code Category",
  "Setup-Procedure Code Category-Able to edit Procedure Code Category",
  "Setup-Procedure Code Category-Able to View/List Procedure Code Category",
  "Setup-Procedure Code Category-Able to Delete Procedure Code Category",
  "Setup-Procedure Code Category-Able to Active/In-Active Procedure Code Category",
  "Setup-Procedure Code-Able to add Procedure Code",
  "Setup-Procedure Code-Able to edit Procedure Code",
  "Setup-Procedure Code-Able to View/List Procedure Code",
  "Setup-Procedure Code-Able to Delete Procedure Code",
  "Setup-Procedure Code-Able to Active/In-Active Procedure Code",
  "Setup-Medical history-Able to add Medical history",
  "Setup-Medical history-Able to edit Medical history",
  "Setup-Medical history-Able to View/List Medical history",
  "Setup-Medical history-Able to Delete Medical history",
  "Setup-Medical history-Able to Active/In-Active Medical history",
  "Setup-Medical category-Able to add Medical category",
  "Setup-Medical category-Able to edit Medical category",
  "Setup-Medical category-Able to View/List Medical category",
  "Setup-Medical category-Able to Delete Medical category",
  "Setup-Medical category-Able to Active/In-Active Medical category",
  "Setup-Medical drafts-Able to edit Medical drafts",
  "Setup-Medical drafts-Able to View/List Medical drafts",
  "Setup-Medical drafts-Able to Delete Medical drafts",
  "Setup-Discount Plan-Able to add Discount Plan",
  "Setup-Discount Plan-Able to edit Discount Plan",
  "Setup-Discount Plan-Able to View/List Discount Plan",
  "Setup-Discount Plan-Able to Delete Discount Plan",
  "Setup-Discount Plan-Able to Active/In-Active Discount Plan",
  "Setup-Pharmacies-Able to add Pharmacies",
  "Setup-Pharmacies-Able to edit Pharmacies",
  "Setup-Pharmacies-Able to View/List Pharmacies",
  "Setup-Pharmacies-Able to Delete Pharmacies",
  "Setup-Pharmacies-Able to Active/In-Active Pharmacies",
  "Setup-Medications-Able to add Medications",
  "Setup-Medications-Able to edit Medications",
  "Setup-Medications-Able to View/List Medications",
  "Setup-Medications-Able to Delete Medications",
  "Setup-Medications-Able to Clone Medications",
  "Setup-Medications-Able to Active/In-Active Medications",
  "Setup-Medications-Able to add Brand",
  "Setup-Medications-Able to edit Brand",
  "Setup-Medications-Able to View/List Brand",
  "Setup-Medications-Able to Delete Brand",
  "Setup-Medications-Able to Active/In-Active Brand",
  "Setup-Diagnosis-Able to add Diagnosis",
  "Setup-Diagnosis-Able to edit Diagnosis",
  "Setup-Diagnosis-Able to View/List Diagnosis",
  "Setup-Diagnosis-Able to Delete Diagnosis",
  "Setup-Diagnosis-Able to Active/In-Active Diagnosis",
  "Setup-Appointment Types-Able to add Appointment Types",
  "Setup-Appointment Types-Able to edit Appointment Types",
  "Setup-Appointment Types-Able to View/List Appointment Types",
  "Setup-Appointment Types-Able to Delete Appointment Types",
  "Setup-Appointment Types-Able to Active/In-Active Appointment Types",
  "Setup-Appointment Reminders-Able to add Provider",
  "Setup-Appointment Reminders-Able to edit Provider",
  "Setup-Appointment Reminders-Able to View/List Provider",
  "Setup-Appointment Reminders-Able to Delete Provider",
  "Setup-Appointment Reminders-Able to Active/In-Active Provider",

  "Setup-Appointment Reminders-Able to add Patient",
  "Setup-Appointment Reminders-Able to edit Patient",
  "Setup-Appointment Reminders-Able to View/List Patient",
  "Setup-Appointment Reminders-Able to Delete Patient",
  "Setup-Appointment Reminders-Able to Active/In-Active Patient",

  "Setup-Recall & Recall Type Setup-Able to add Recall & Recall Type Setup",
  "Setup-Recall & Recall Type Setup-Able to setting Recall & Recall Type Setup",
  "Setup-Recall & Recall Type Setup-Able to edit Recall & Recall Type Setup",
  "Setup-Recall & Recall Type Setup-Able to View/List Recall & Recall Type Setup",
  "Setup-Recall & Recall Type Setup-Able to Delete Recall & Recall Type Setup",
  "Setup-Recall & Recall Type Setup-Able to Active/In-Active Recall & Recall Type Setup",
  "Setup-Roles & Permissions-Able to add Roles & Permissions",
  "Setup-Roles & Permissions-Able to copy Roles & Permissions",
  "Setup-Roles & Permissions-Able to edit Roles & Permissions",
  "Setup-Roles & Permissions-Able to View/List Roles & Permissions",
  "Setup-Roles & Permissions-Able to Delete Roles & Permissions",
  "Setup-Roles & Permissions-Able to Active/In-Active Roles & Permissions",
  "Setup-Operatories-Able to add Operatories",
  "Setup-Operatories-Able to edit Operatories",
  "Setup-Operatories-Able to combine Operatories",
  "Setup-Operatories-Able to View/List Operatories",
  "Setup-Operatories-Able to Delete Operatories",
  "Setup-Operatories-Able to Active/In-Active Operatories",

  "Setup-Blockout-Able to add Blockout",
  "Setup-Blockout-Able to edit Blockout",
  "Setup-Blockout-Able to View/List Blockout",

  "Setup-Referrals Slips-Able to add Referrals Slips",
  "Setup-Referrals Slips-Able to edit Referrals Slips",
  "Setup-Referrals Slips-Able to View/List Referrals Slips",
  "Setup-Adjustment Types-Able to add Adjustment Types",
  "Setup-Adjustment Types-Able to edit Adjustment Types",
  "Setup-Adjustment Types-Able to View/List Adjustment Types",
  "Setup-Adjustment Types-Able to Delete Adjustment Types",
  "Setup-Adjustment Types-Able to Active/In-Active Adjustment Types",
  "Setup-Employers-Able to add Employers",
  "Setup-Employers-Able to edit Employers",
  "Setup-Employers-Able to View/List Employers",
  "Setup-Employers-Able to Delete Employers",
  "Setup-Employers-Able to Active/In-Active Employers",
  "Setup-Billing Types-Able to add Billing Types",
  "Setup-Billing Types-Able to edit Billing Types",
  "Setup-Billing Types-Able to View/List Billing Types",
  "Setup-Billing Types-Able to Delete Billing Types",
  "Setup-Billing Types-Able to Active/In-Active Billing Types",
  "Setup-Insurance-Able to add Insurance",
  "Setup-Insurance-Able to edit Insurance",
  "Setup-Insurance-Able to View/List Insurance",
  "Setup-Insurance-Able to Delete Insurance",
  "Setup-Insurance-Able to Active/In-Active Insurance",
  "Setup-Insurance category-Able to add Insurance category",
  "Setup-Insurance category-Able to edit Insurance category",
  "Setup-Insurance category-Able to View/List Insurance category",
  "Setup-Insurance category-Able to Delete Insurance category",
  "Setup-Insurance category-Able to Active/In-Active Insurance category",
  "Setup-Fee Schedule-Able to add Fee Schedule",
  "Setup-Fee Schedule-Able to edit Fee Schedule",
  "Setup-Fee Schedule-Able to View/List Fee Schedule",
  "Setup-Fee Schedule-Able to Delete Fee Schedule",
  "Setup-Fee Schedule-Able to Active/In-Active Fee Schedule",

  "Template Library Template-Able to add Template",
  "Template Library Template-Able to edit Template",
  "Template Library Template-Able to copy Template",
  "Template Library Template-Able to View/List Template",
  "Template Library Template-Able to Delete Template",
  "Template Library Template-Able to Active/In-Active Template",

  "Template Library category-Able to add category",
  "Template Library category-Able to edit category",
  "Template Library category-Able to View/List category",
  "Template Library category-Able to Delete category",
  "Template Library category-Able to Active/In-Active category",

  "Template Library Draft-Able to edit Draft",
  "Template Library Draft-Able to View/List Draft",
  "Template Library Draft-Able to Delete Draft",
  "Template Library Draft-Able to Active/In-Active Draft",

  "Setup-Treatment Plan: Priorities-Able to add Priorities",
  "Setup-Treatment Plan: Priorities-Able to edit Priorities",
  "Setup-Treatment Plan: Priorities-Able to View/List Priorities",
  "Setup-Treatment Plan: Priorities-Able to Delete Priorities",
  "Setup-Treatment Plan: Priorities-Able to Active/In-Active Priorities",
  "Setup-Prescriptions-Able to add Prescriptions",
  "Setup-Prescriptions-Able to edit Prescriptions",
  "Setup-Prescriptions-Able to View/List Prescriptions",
  "Setup-Prescriptions-Able to Delete Prescriptions",
  "Setup-Prescriptions-Able to Active/In-Active Prescriptions",
  "Setup-Policies-Able to edit Policies",
  "Setup-Policies-Able to View/List Policies",
  "Audit Trails-Able to view Audit Trails",
  "Time Clock-Able to view time clock",
  "Time Clock-Able to apply for regularization",
  "Time Clock-Able to approve regularization",
  "Setup-Note Type-Able to add Note Type",
  "Setup-Note Type-Able to edit Note Type",
  "Setup-Note Type-Able to View/List Note Type",
  "Setup-Note Type-Able to Delete Note Type",
  "Time Clock-Able to view all time clock",
  "Time Clock-Able to view own time clock",
];
export const colorArry = [
  // { value: '#D7EBF4' },
  // { value: '#D9E3F2' },
  // { value: '#DCFFDC' },
  // { value: '#FFD7D7' },
  // { value: '#FFDB9D' },
  // { value: '#FFFFCE' },

  { value: '#D1F9EB' },
  { value: '#D1F9DC' },
  { value: '#D6F9D1' },
  { value: '#E5F9D1' },
  { value: '#F4F9D1' },
  { value: '#F9F0D1' },
  { value: '#F9E1D1' },
  { value: '#F9D2D1' },
  { value: '#FAD2E1' },
  { value: '#F9D1EF' },
  { value: '#F5D1F9' },
  { value: '#E6D1F9' },
  { value: '#D7D1F9' },
  { value: '#D1DBF9' },
  { value: '#D1EAF9' },
  { value: '#D1F9F9' },
  { value: '#FCF4DE' },
  { value: '#FCE9DE' },
  { value: '#FCDEDE' },
  { value: '#FCDEEA' },
  { value: '#FCDEF5' },
  { value: '#F8DEFC' },
  { value: '#ECDEFC' },
  { value: '#E1DEFC' },
  { value: '#DFE7FD' },
  { value: '#DEF1FC' },
  { value: '#DEFCFB' },
  { value: '#DEFCF0' },
  { value: '#DEFCE5' },
  { value: '#E2FCDE' },
  { value: '#EDFCDE' },
  { value: '#F9FCDE' },
  { value: '#E5BEBE' },
  { value: '#E0C2C3' },
  { value: '#DBC7C8' },
  { value: '#D6CBCD' },
  { value: '#D2D0D2' },
  { value: '#CDD4D7' },
  { value: '#C8D8DC' },
  { value: '#C3DDE1' },
  { value: '#BEE1E6' },
  { value: '#BEDDE6' },
  { value: '#BED8E6' },
  { value: '#BED4E6' },
  { value: '#BED0E6' },
  { value: '#BECBE5' },
  { value: '#BEC7E5' },
  { value: '#BEC2E5' },
  { value: '#BEBEE5' },
  { value: '#FFEBE6' },
]
export const timeZonelist = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    text: "(UTC-12:00) International Date Line West",
    timezone: "Pacific/Wallis"
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    timezone: "Pacific/Pago_Pago"
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    timezone: "US/Hawaii"
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -9,
    isdst: true,
    text: "(UTC-09:00) Alaska",
    timezone: "US/Alaska"
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -8,
    isdst: true,
    text: "(UTC-08:00) Baja California",
    timezone: "America/Pacific"
  },
  {
    value: "Pacific Standard Time",
    abbr: "PDT",
    offset: -8,
    isdst: true,
    text: "(UTC-08:00) Pacific Time (US & Canada)",
    timezone: "America/Pacific"
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    timezone: "America/Mountain"
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    timezone: "America/Chihuahua"
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    timezone: "US/Mountain"
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Central America",
    timezone: "America/Managua"
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -6,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    timezone: "US/Central"
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -6,
    isdst: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    timezone: "America/Mexico_City"
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Saskatchewan",
    timezone: "Canada/Saskatchewan"
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    timezone: "America/Lima"
  },
  {
    value: "Eastern Standard Time",
    abbr: "EDT",
    offset: -5,
    isdst: true,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    timezone: "US/Eastern"
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -5,
    isdst: true,
    text: "(UTC-05:00) Indiana (East)",
    timezone: "US/East-Indiana"
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    text: "(UTC-04:30) Caracas",
    timezone: "America/Caracas"
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    timezone: "America/Santiago"
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -4,
    isdst: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    timezone: "Canada/Atlantic"
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    timezone: "America/Sao_Paulo"
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    timezone: "America/La_Paz"
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Santiago",
    timezone: "America/Santiago"
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -3.5,
    isdst: true,
    text: "(UTC-03:30) Newfoundland",
    timezone: "Canada/Newfoundland"
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Brasilia",
    timezone: "America/Sao_Paulo"
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    timezone: "America/Argentina/Buenos_Aires"
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    timezone: "America/Cayenne"
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -3,
    isdst: true,
    text: "(UTC-03:00) Greenland",
    timezone: "America/Godthab"
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Montevideo",
    timezone: "America/Montevideo"
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Salvador",
    timezone: "America/Montevideo"
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    timezone: "America/Godthab"
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -2,
    isdst: true,
    text: "(UTC-02:00) Mid-Atlantic - Old",
    timezone: "America/Noronha"
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: -1,
    isdst: true,
    text: "(UTC-01:00) Azores",
    timezone: "Atlantic/Azores"
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    text: "(UTC-01:00) Cape Verde Is.",
    timezone: "Atlantic/Cape_Verde"
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 0,
    isdst: true,
    text: "(UTC) Casablanca",
    timezone: "Africa/Casablanca"
  },
  {
    value: "UTC",
    abbr: "CUT",
    offset: 0,
    isdst: false,
    text: "(UTC) Coordinated Universal Time",
    timezone: "Etc/Greenwich"
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 0,
    isdst: true,
    text: "(UTC) Dublin, Edinburgh, Lisbon, London",
    timezone: "Europe/London"
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    text: "(UTC) Monrovia, Reykjavik",
    timezone: "Africa/Monrovia"
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    timezone: "Europe/Amsterdam"
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    timezone: "Europe/Budapest"
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    timezone: "Europe/Paris"
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    timezone: "Europe/Warsaw"
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) West Central Africa",
    timezone: "Africa/Lagos"
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) Windhoek",
    timezone: "Africa/Windhoek"
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Athens, Bucharest",
    timezone: "Europe/Athens"
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Beirut",
    timezone: "Asia/Beirut"
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Cairo",
    timezone: "Africa/Cairo"
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Damascus",
    timezone: "Asia/Damascus"
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) E. Europe",
    timezone: "Europe/London"
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Harare, Pretoria",
    timezone: "Africa/Harare"
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    timezone: "Europe/Helsinki"
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Istanbul",
    timezone: "Europe/Istanbul"
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Jerusalem",
    timezone: "Asia/Jerusalem"
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Tripoli",
    timezone: "Africa/Tripoli"
  },
  {
    value: " Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Amman",
    timezone: "Asia/Amman"
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Baghdad",
    timezone: "Asia/Baghdad"
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kaliningrad, Minsk",
    timezone: "Europe/Minsk"
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    timezone: "Asia/Kuwait"
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Nairobi",
    timezone: "Africa/Nairobi"
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 3.5,
    isdst: true,
    text: "(UTC+03:30) Tehran",
    timezone: "Asia/Tehran"
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    timezone: "Asia/Muscat"
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 4,
    isdst: true,
    text: "(UTC+04:00) Baku",
    timezone: "Asia/Baku"
  },
  {
    value: "Russian Standard Time",
    abbr: "RST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    timezone: "Europe/Moscow"
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Port Louis",
    timezone: "Indian/Mauritius"
  },
  {
    value: "Georgian Standard Time",
    abbr: "GST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Tbilisi",
    timezone: "Asia/Tbilisi"
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Yerevan",
    timezone: "Asia/Yerevan"
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    text: "(UTC+04:30) Kabul",
    timezone: "Asia/Kabul"
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    timezone: "Asia/Tashkent"
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    timezone: "Asia/Karachi"
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    timezone: "Asia/Kolkata"
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    timezone: "Asia/Calcutta"
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    text: "(UTC+05:45) Kathmandu",
    timezone: "Asia/Katmandu"
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Astana",
    timezone: "Asia/Dhaka"
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Dhaka",
    timezone: "Asia/Dhaka"
  },
  {
    value: "Ekaterinburg Standard Time",
    abbr: "EST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Ekaterinburg",
    timezone: "Asia/Yekaterinburg"
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    timezone: "Asia/Rangoon"
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    timezone: "Asia/Bangkok"
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Novosibirsk",
    timezone: "Asia/Novosibirsk"
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    timezone: "Asia/Hong_Kong"
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Krasnoyarsk",
    timezone: "Asia/Krasnoyarsk"
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    timezone: "Asia/Kuala_Lumpur"
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Perth",
    timezone: "Australia/Perth"
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Taipei",
    timezone: "Asia/Taipei"
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Ulaanbaatar",
    timezone: "Asia/Ulaanbaatar"
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Irkutsk",
    timezone: "Asia/Irkutsk"
  },
  {
    value: "Tokyo Standard Time",
    abbr: "TST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    timezone: "Asia/Tokyo"
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Seoul",
    timezone: "Asia/Seoul"
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Adelaide",
    timezone: "Australia/Adelaide"
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Darwin",
    timezone: "Australia/Darwin"
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Brisbane",
    timezone: "Australia/Brisbane"
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    timezone: "Australia/Melbourne"
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    timezone: "Pacific/Port_Moresby"
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Hobart",
    timezone: "Australia/Hobart"
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Yakutsk",
    timezone: "Asia/Yakutsk"
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    timezone: "Pacific/Guadalcanal"
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Vladivostok",
    timezone: "Asia/Vladivostok"
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Auckland, Wellington",
    timezone: "Pacific/Auckland"
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    timezone: "Pacific/Kwajalein"
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Fiji",
    timezone: "Pacific/Fiji"
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Magadan",
    timezone: "Asia/Magadan"
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    timezone: "Asia/Kamchatka"
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Nuku'alofa",
    timezone: "Pacific/Tongatapu"
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Samoa",
    timezone: "Pacific/Samoa"
  }

];

