import { axiosInstance, baseUrl, handleErrors } from "./auth";

// Add PATIENT
export const ADD_PATIENT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PATIENT_LIST
export const GET_PATIENT_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PATIENT_DETAILS
export const GET_PATIENT_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patient/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// UPDATE_PATIENT
export const UPDATE_PATIENT = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/patient`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// Add PATIENT_FAMILY_MEMBER
export const ADD_PATIENT_FAMILY_MEMBER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/member`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PATIENT_FAMILY_MEMBER_LIST
export const GET_PATIENT_FAMILY_MEMBER_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//DELETE_FAMILIY_MEMBER
export const DELETE_FAMILIY_MEMBER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PATIENT_FAMILY_MEMBER_DETAILS
export const GET_PATIENT_FAMILY_MEMBER_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patient/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// UPDATE_GET_PATIENT_FAMILY_MEMBER
export const UPDATE_GET_PATIENT_FAMILY_MEMBER = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/patient`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// provider/list /GET_PROVIDER_LIST /HYGIENIST Provider
export const GET_PROVIDER_HYGIENIST_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/provider/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// insurance/list
export const GET_PATIENT_INSURANCE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insurance/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PATIENT_INSURANCE_DETAILS
export const GET_PATIENT_INSURANCE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/insurance/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//Patient Insurance Delete
export const DELETE_INSURANCE = async (params) => {
    try {
        const response = await axiosInstance.delete(`${baseUrl}/insurance/delete/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//Patient Insurance UPDATE
export const UPDATE_INSURANCE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/insurance/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// makePrimary
export const MAKE_PRIMARY = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patient/makePrimary/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// separateMember
export const MAKE_SEPARATE_MEMBER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/separateMember`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// patientCard/add
export const ADD_PATIENT_CARD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patientCard/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// get patientCard
export const GET_PATIENT_CARD = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patientCard/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//Patient Card Delete
export const DELETE_PATIENT_CARD = async (params) => {
    try {
        const response = await axiosInstance.delete(`${baseUrl}/patientCard/delete/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// patient merge account 
export const MERGE_PATIENT_ACCOUNT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/merge`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// PATIENT_DOCUMENT_ADD
export const PATIENT_DOCUMENT_ADD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patientDocument`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// PATIENT_DOCUMENT_LIST
export const PATIENT_DOCUMENT_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patientDocument/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// PATIENT_DOCUMENT_DELETE
export const PATIENT_DOCUMENT_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patientDocument/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PATIENT_DOCUMENT
export const GET_PATIENT_DOCUMENT = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patientDocument/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// UPDATE_PATIENT_DOCUMENT
export const UPDATE_PATIENT_DOCUMENT = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/patientDocument`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// Xray
export const X_RAY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/xrays/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const ADD_X_RAY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/xrays`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const DELETE_X_RAY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/xrays/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const GET_X_RAY_DEATILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/patientDocument/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const UPDATE_X_RAY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/xrays`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// /auditTrail/patientTimeline/list
export const PATIENT_TIMELINE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/auditTrail/patientTimeline/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}