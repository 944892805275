import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import AuthSlice from '../Slices/AuthSlice';
import TowFactorSlice from '../Slices/TowFactorSlice';
import PatientSlice from '../Slices/PatientSlice';
import TreatmentPlanSlice from '../Slices/TreatmentPlanSlice';

const encryptor = encryptTransform({
    secretKey: 'Pritam_Singh_Zimo_Infotech_74_dental_superAdmin',
});
const reducers = combineReducers({
    auth: AuthSlice,
    TowFactor: TowFactorSlice,
    PatientData: PatientSlice,
    TreatmentPlanData: TreatmentPlanSlice,
});
const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
    blacklist: ["TreatmentPlanData"],
    whitelist: ["auth", "TowFactor", "PatientData"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const Store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Ignore serialization checks
        }),
});

export default Store;