import { useState } from 'react';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
const PhoneNumber = ({ Name, Value, HandleChange, disabledInput, width, countrySelectComponent, error }) => {
    const [countryMaxLength, setCountryMaxLength] = useState(15);
    const formatPhoneNumber = (value) => {
        if (!value) return '';
        const phoneNumber = value.replace(/[^\d]/g, '');
        const countryCode = phoneNumber.slice(0, 3);
        const number = phoneNumber.slice(3);
        return `+${countryCode} ${number}`;
    };
    const handleCountryChange = (selectedCountry) => {
        const countryName = selectedCountry;
        if (countryName === 'IN') {
            setCountryMaxLength((pre) => 15);
        } else {
            setCountryMaxLength((pre) => 20);
        }
    };
    return (
        <>
            <PhoneInput
                disabled={disabledInput}
                className={`${width && "w-100"}`}
                // defaultCountry={(timeZone == "Asia/Calcutta" || timeZone === "Asia/Kolkata") ? 'IN' : undefined}
                // countrySelectComponent={(timeZone == "Asia/Calcutta" || timeZone === "Asia/Kolkata") ? "null" : undefined}
                countrySelectComponent={countrySelectComponent}
                defaultCountry={'US'}
                countryCallingCodeEditable={false}
                international
                withCountryCallingCode
                value={Value && `${Value.includes("+") ? "" : "+"}${Value}`}
                onChange={(e) => HandleChange({ target: { name: Name, value: e } })}
                format={formatPhoneNumber}
                maxLength={countryMaxLength}  // Set the dynamic maxLength
                onCountryChange={(country) => handleCountryChange(country)}
            />
            {error && <p className="font-13" style={{ color: 'red' }}>{error}</p>}
        </>

    );
};
export default PhoneNumber