import { useState, useEffect, useRef } from 'react'
import { GET_CLINIC_LOCATION_LIST, SPECIALITY_LIST } from '../../../repository/ClinicRepo'
import { ADD_PATIENT, ADD_PATIENT_FAMILY_MEMBER, GET_PATIENT_LIST, GET_PROVIDER_HYGIENIST_LIST, MERGE_PATIENT_ACCOUNT } from '../../../repository/patientRepo'
import { COUNTRY_LIST, STATE_LIST, CITY_LIST } from '../../../repository/common'
import { toast } from "react-toastify"
import { Validation } from '../../common-components/validations'
import useReduxStore from '../../../hooks/useReduxStore'
import { requiredMessage } from '../../../Utils'
import moment from 'moment-timezone'
import { modalEnum, patientFormEnums } from '../../../constant'
import { useDispatch } from 'react-redux'
import { patientDetails } from "../../../Slices/PatientSlice"
import useUploadImage from '../../../hooks/useUploadImage'
import { GET_PRIVACYMODE, UPDATE_PRIVACYMODE } from '../../../repository/schedulerRepo'
import { add } from "../../../Slices/AuthSlice";
import { useNavigate } from 'react-router-dom'
import { LOGOUT } from '../../../repository/auth'


const useHeader = () => {
    const { Dispatch, selector: user } = useReduxStore("auth")
    const { selector: PatientData } = useReduxStore("PatientData")
    const mergeType = { duplicate: 'DUPLICATE', familyMember: 'FAMILYMEMBER' }

    const dispatch = useDispatch()
    const { UploadImage } = useUploadImage()
    const FileRef = useRef()
    const [licenseImage, setLicenseImage] = useState(null)
    const [spinner, setSpinner] = useState(false)
    const [hippModeOnOff, setHippModeOnOff] = useState(false)
    const [createAppointmentModal, setCreateAppointmentModal] = useState({
        open: false,
        data: null,
        type: null
    })
    const [selectPatientModal, setSelectPatientModal] = useState(modalEnum)
    const [addPatientModal, setAddPatientModal] = useState(modalEnum)
    const [familyMemberModal, setFamilyMemberModal] = useState(modalEnum)
    const [mergeAccountModal, setMergeAccountModal] = useState(modalEnum)
    const [mergeConfirmModal, setMergeConfirmModal] = useState(modalEnum)
    const [patientListData, setPatientListData] = useState([])
    const [patientMergeList, setPatientMergeList] = useState([])

    const [countryListData, setCountryListData] = useState([])
    const [stateListData, setStateListData] = useState([])
    const [cityListData, setCityListData] = useState([])
    const [SpecialityListData, setSpecialityListData] = useState([])
    const [HygienistListData, setHygienistListData] = useState([])
    const [providerListData, setProviderListData] = useState([])
    const [patientFormInput, setPatientFormInput] = useState(patientFormEnums)
    const [punch, setPunch] = useState(true);
    const { title, firstName, middleName, lastName, salutation, preferredPronoun, gender, maritalStatus, dob, age, status, bloodGroup, isOrthoPatient, street, suiteAptNumber, region, country, state, city, zipcode, preferredContactMethod, phone, workPhone, email, emergencyContactPersonName, emergencyContactPersonPhoneNumber, relationshipWithPatient, paymentMode, referenceTo, referenceBy, responsibleParty, language, policyHolder, employmentType, preferredProvider, preferredHygienist,
    } = patientFormInput
    const [searchText, setSearchText] = useState("")
    const [action, setAction] = useState(false)
    const [actionPatient, setActionPatient] = useState(false)
    const [patientError, setPatientError] = useState({})
    const [selectedMergeOptions, setSelectedMergeOptions] = useState({ primary: null, secondary: null });
    const [totalCount, settotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });

    const [ClinicLocationListListData, setClinicLocationListData] = useState([])
    const navigate = useNavigate()

    const clearSelectedMerge = () => {
        setSelectedMergeOptions({ primary: null, secondary: null });
    }
    const clearForm = () => {
        setPatientFormInput(patientFormEnums)
        setPatientError({})
        setLicenseImage(null)
    }
    // console.log(patientError, "patientError")

    useEffect(() => {
        getPrivacyMode()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (addPatientModal?.open || familyMemberModal?.open) {
            getCountry()
            getSpecialityList()
            getProivderList()
            getProivderHygienistList()
        }
    }, [addPatientModal, familyMemberModal])
    useEffect(() => {
        if (selectPatientModal.open) {
            getPatientList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionPatient, selectPatientModal, controller])

    useEffect(() => {
        getMergePatientList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    // GET_PRIVACY_MODE
    let getPrivacyMode = async () => {
        try {
            setSpinner(true)
            let response = await GET_PRIVACYMODE()
            setHippModeOnOff(response?.data?.privacyMode)
            let hippamode = { ...PatientData, hippModeOnOff: response?.data?.privacyMode }
            dispatch(patientDetails(hippamode))
        } catch (error) {
            toast.error(error?.message)
        }
        setSpinner(false)
    }
    let updatePrivacyMode = async (mode) => {
        try {
            setSpinner(true)
            let response = await UPDATE_PRIVACYMODE({ privacyMode: mode })
            toast.success(response?.message)
            getPrivacyMode()
        } catch (error) {
            toast.error(error?.message)
        }
        setSpinner(false)
    }

    // GET_PATIENT_LIST
    const getPatientList = async (countryId) => {
        try {
            let payload = {
                count: controller?.rowsPerPage,
                page: controller?.page,
                clinicId: user?.clinic_id
                // isDeleted: false,
            }
            if (searchText) {
                payload.searchName = searchText
            }
            setSpinner(true)
            let response = await GET_PATIENT_LIST(payload)
            settotalCount(response?.count)
            setPatientListData(response?.data?.map((cur) => {
                return { ...cur, fullName: `${cur?.firstName || ""} ${cur?.lastName || ""}` }
            }))

        } catch (error) {
            toast.error(error?.message)
        }
        setSpinner(false)
    }

    // GET Merge _PATIENT_LIST
    const getMergePatientList = async (countryId) => {
        try {
            let payload = {
                count: 10000,
                page: 1,
                clinicId: user?.clinic_id,
                listOnlyPrimary: true,
                // isDeleted: false,
            }
            if (searchText) {
                payload.searchName = searchText
            }
            setSpinner(true)
            let response = await GET_PATIENT_LIST(payload)
            setPatientMergeList(response?.data)

        } catch (error) {
            toast.error(error?.message)
        }
        setSpinner(false)
    }

    // Handle Same As Primary Patient
    const handleSameAsPrimaryPatient = async (event) => {
        if (event.target.checked) {
            // console.log(PatientData, "partet")
            if (PatientData?.country) {
                await getCountry(PatientData?.country)
            }
            if (PatientData?.state) {
                await getState(PatientData?.country, PatientData?.state)
            } if (PatientData?.city) {
                await getCity(PatientData?.country, PatientData?.state, PatientData?.city)
            }

            setPatientFormInput(prev => ({
                ...prev,
                street: PatientData?.street,
                suiteAptNumber: PatientData?.suiteAptNumber,
                region: PatientData?.region,
                // country: PatientData?.country,
                // state: "",
                // city: "",
                zipcode: PatientData?.zipcode,
            }));
        } else {
            setPatientFormInput(prev => ({
                ...prev,
                street: "",
                suiteAptNumber: "",
                region: "",
                country: "",
                state: "",
                city: "",
                zipcode: "",
            }));
        }
    }


    const getCountry = async (countryId) => {
        try {
            let payload = {
                count: 10000,
                isDeleted: false,
            }
            setSpinner(true)
            let response = await COUNTRY_LIST(payload)
            setCountryListData(response.data)
            if (countryId) {
                let newCountry = response?.data?.filter(item => item.id === countryId)
                setPatientFormInput((prev) => ({ ...prev, country: newCountry }))
            }
        } catch (error) {

        }
        setSpinner(false)
    }

    const logout = async () => {
        try {
            let payload = {
                token: user?.token
            }
            setSpinner(true)
            await LOGOUT(payload)
            Dispatch(add({}))
            Dispatch(patientDetails({}))
            navigate("/")
        } catch (error) {

        }
        setSpinner(false)
    }

    // Get State List
    const getState = async (countryId, stateID) => {
        try {
            let payload = {
                count: 10000,
                isDeleted: false,
                countryId: countryId
            }
            setSpinner(true)
            let response = await STATE_LIST(payload)
            if (stateID) {
                let newState = response?.data?.filter(item => item.id === stateID)
                setPatientFormInput((prev) => ({ ...prev, state: newState }))
            }
            setStateListData(response.data)
        } catch (error) {

        }
        setSpinner(false)
    }
    // Get City List
    const getCity = async (countryId, stateID, addressCity1) => {
        try {
            let payload = {
                count: 10000,
                countryId: countryId,
                isDeleted: false,
                stateId: stateID
            }
            setSpinner(true)
            let response = await CITY_LIST(payload)
            setCityListData(response.data)
            if (addressCity1) {
                let newCity = response?.data?.filter(item => item.id === addressCity1)
                setPatientFormInput((prev) => ({ ...prev, city: newCity }))
            }
        } catch (error) {

        }
        setSpinner(false)
    }
    // providerList
    let getProivderList = async (providerId) => {
        try {
            let payload = {
                count: 10000,
                isDeleted: false,
            }
            setSpinner(true)
            let response = await GET_PROVIDER_HYGIENIST_LIST(payload)
            setProviderListData(response.data)
            if (providerId) {
                let newProvider = response?.data?.filter(item => item.providerId === providerId)
                setPatientFormInput((prev) => ({ ...prev, preferredProvider: newProvider }))
            }
        } catch (error) {

        }
        setSpinner(false)
    }
    // get Proivder Hygienist List
    let getProivderHygienistList = async (hygienistId) => {
        try {
            let payload = {
                count: 10000,
                isDeleted: false,
                roleName: "HYGIENIST",
            }
            setSpinner(true)
            let response = await GET_PROVIDER_HYGIENIST_LIST(payload)
            setHygienistListData(response.data)
            if (hygienistId) {
                let newHygienist = response?.data?.filter(item => item.providerId === hygienistId)
                setPatientFormInput((prev) => ({ ...prev, preferredHygienist: newHygienist }))
            }
        } catch (error) {

        }
        setSpinner(false)
    }
    // Speciality List
    const getSpecialityList = async () => {
        setSpinner(true)
        try {
            let payload = {
            }
            let response = await SPECIALITY_LIST(payload)
            setSpecialityListData(response.data)
        } catch (error) {
            toast.error(error.message)
            setSpecialityListData([])
        }
        setSpinner(false)

    }

    // handle merge acount radio input's
    const handleMergeRadioChange = (patData) => {
        setSelectedMergeOptions((prev) => {
            if (prev.primary === null) {
                return { ...prev, primary: patData?.id, primaryData: patData };
            } else if (prev.secondary === null) {
                return { ...prev, secondary: patData?.id, secondaryData: patData };
            } else {
                return prev; // Do not update if both primary and secondary are already selected
            }
        });
    };

    // patientDetails

    const selectedPatient = (row) => {
        let newData = { ...PatientData, ...row }
        dispatch(patientDetails(newData))
        setSelectPatientModal({ data: null, open: false })
    }
    // Calculate age
    const handalDOB = (value) => {
        let today = new Date();
        let birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        setPatientFormInput((prevProps) => ({ ...prevProps, age: age }));
        setPatientError((pre) => {
            delete pre["age"];
            return { ...pre };
        });
    }
    // handle Clinic Location Input
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;

        setPatientFormInput((prevProps) => ({
            ...prevProps,
            [name]: newValue
        }));
        if (name === "dob") {
            handalDOB(value);
        }
        setPatientError((prev) => {
            const { [name]: removed, ...rest } = prev;
            return rest;
        });
    };

    // Error Message
    const errorMessage = {
        firstName: requiredMessage('first name'),
        lastName: requiredMessage('last name'),
        preferredPronoun: requiredMessage('preferred pronoun'),
        dob: requiredMessage('dob'),
        age: requiredMessage('age'),
        status: requiredMessage('status'),
        preferredContactMethod: requiredMessage('preferred contact method'),
        phone: requiredMessage('Phone number'),
        email: requiredMessage('email'),
    }
    // Clinic Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!firstName) {
            errorObj['firstName'] = errorMessage['firstName']
        }
        if (!lastName) {
            errorObj['lastName'] = errorMessage['lastName']
        }
        if (!preferredPronoun?.length) {
            errorObj['preferredPronoun'] = errorMessage['preferredPronoun']
        }
        if (!dob) {
            errorObj['dob'] = errorMessage['dob']
        }
        if (!age) {
            errorObj['age'] = errorMessage['age']
        }
        if (!status?.length) {
            errorObj['status'] = errorMessage['status']
        }
        if (!preferredContactMethod) {
            errorObj['preferredContactMethod'] = errorMessage['preferredContactMethod']
        }
        if (!phone) {
            errorObj['phone'] = errorMessage['phone']
        }
        if (!email) {
            errorObj['email'] = errorMessage['email']
        } else if (!patientFormInput.email.match(Validation.pattern.EMAIL)) {
            errorObj["email"] = "Please enter valid email";
        }
        setPatientError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    // Add Patient form||ADD_PATIENT_FAMILY_MEMBER
    const handleAddSubmit = async (event, type) => {
        event.preventDefault()
        if (!isValid()) return
        setSpinner(true)
        const formattedDob = moment(dob).format("YYYY-MM-DD");
        try {
            let payload = {
                clinicId: user?.clinic_id,
                createdById: "",
                title: title,
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                salutation: salutation,
                preferredPronoun: preferredPronoun?.[0]?.value,
                gender: gender?.[0]?.value,
                maritalStatus: maritalStatus?.[0]?.value,
                dob: formattedDob,
                age: age,
                status: status?.[0]?.value,
                bloodGroup: bloodGroup?.[0]?.value,
                isOrthoPatient: isOrthoPatient?.[0]?.value,
                street: street,
                suiteAptNumber: suiteAptNumber,
                region: region[0]?.value,
                country: country[0]?.id,
                state: state[0]?.id,
                city: city[0]?.id,
                zipCode: zipcode,
                preferredContactMethod: preferredContactMethod,
                phone: phone,
                workPhone: workPhone,
                email: email,
                emergencyContactPersonName: emergencyContactPersonName,
                emergencyContactPersonPhoneNumber: emergencyContactPersonPhoneNumber,
                relationshipWithPatient: relationshipWithPatient[0]?.value,
                driversLicense: licenseImage,
                responsibleParty: responsibleParty,
                language: language,
                policyHolder: policyHolder,
                employmentType: employmentType,
                preferredProvider: preferredProvider[0]?.providerId,
                preferredHygienist: preferredHygienist[0]?.providerId,
                paymentMode: paymentMode[0]?.value,
                // billingType: billingType[0]?.value,
                billingType: null,

                referredTo: referenceTo,
                referredBy: referenceBy,
            }
            if (type === "FAMILY") {
                payload.parentId = PatientData?.id
                let response = await ADD_PATIENT_FAMILY_MEMBER(payload)
                setFamilyMemberModal({ open: false, data: null })
                // clearForm()
                toast.success(response.message)
            } else {
                let response = await ADD_PATIENT(payload)
                setAddPatientModal({ data: null, open: false })
                toast.success(response.message)
            }
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }
    // const punchInOut = async (type) => {
    //     console.log(type,"type")
    //     try {
    //         setSpinner(true)
    //         const payLoad = {
    //             userId: user?.user_id,
    //             clinicId: user?.clinic_id,
    //             type: type,
    //             locationId: 7
    //         }
    //         const response = await PUNCHINOUT(payLoad)
    //         toast.success(response?.message)
    //         // setPunch(!punch);
    //         punchStatus()

    //     }
    //     catch {

    //     }
    //     setSpinner(false)
    // }
    // const punchStatus = async () => {
    //     try {
    //         const payLoad = {
    //             userId: user?.user_id,
    //             clinicId: user?.clinic_id,
    //             locationId: 7
    //         }
    //         const response = await CURRENT_STATUS(payLoad)
    //         setPunch(response.data.show === "PUNCHIN" ? true : false)
    //     }
    //     catch {

    //     }
    // }


    //MERGE_PATIENT_ACCOUNT
    const mergePatinetHandler = async () => {
        try {
            let payload = {
                mergeType: mergeAccountModal?.type === mergeType?.duplicate ? "MERGE_DUPLICATE" : "MERGE_FAMILY_MEMBER",
                mergeIntoId: selectedMergeOptions?.primary,
                patientToMergeId: selectedMergeOptions?.secondary,
            }
            setSpinner(true)
            let response = await MERGE_PATIENT_ACCOUNT(payload)
            setMergeConfirmModal({ open: false, data: null })
            setMergeAccountModal({ open: false, data: null })

            clearSelectedMerge()
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    const applayFilter = (type) => {
        if (type === "Merge") {
            getMergePatientList()
        } else {
            getPatientList()
        }
    }
    const reset = (type) => {
        if (type === "Merge") {
            setAction(!action)
        } else {
            setActionPatient(!actionPatient)
        }
        setSearchText('')
    }


    // GET_CLINIC_LOCATION_LIST
    const getClinicLocationList = async () => {
        setSpinner(true)
        try {
            let payload = {}
            // if (filter?.searchText) {
            //     payload.searchName = filter.searchText
            // }

            let response = await GET_CLINIC_LOCATION_LIST(payload)
            setClinicLocationListData(response?.data)
        } catch (error) {

        }
        setSpinner(false)
    }

    useEffect(() => {
        getClinicLocationList()
    }, [])

    return {
        data: {
            spinner,
            addPatientModal,
            familyMemberModal,
            selectPatientModal,
            createAppointmentModal,
            hippModeOnOff,
            //Patient data--------------//
            patientFormInput,
            countryListData,
            stateListData,
            cityListData,
            patientError,
            SpecialityListData,
            patientListData,
            providerListData,
            HygienistListData,
            licenseImage,
            FileRef,
            // selectedMergeOptions----------------//
            selectedMergeOptions,
            mergeAccountModal,
            mergeConfirmModal,
            patientMergeList,
            mergeType,
            searchText,
            punch,
            totalCount,
            controller,
            ClinicLocationListListData
        },
        methods: {
            setSpinner,
            setAddPatientModal,
            setFamilyMemberModal,
            setSelectPatientModal,
            reset,
            applayFilter,
            setSearchText,
            setCreateAppointmentModal,
            setHippModeOnOff,
            updatePrivacyMode,
            UploadImage,
            setLicenseImage,
            //patient methods------------//
            getState,
            getCity,
            clearForm,
            handleInputChange,
            handleAddSubmit,
            selectedPatient,
            handleSameAsPrimaryPatient,
            // patient merge -----------//
            setMergeAccountModal,
            setMergeConfirmModal,
            setSelectedMergeOptions,
            handleMergeRadioChange,
            mergePatinetHandler,
            getMergePatientList,
            clearSelectedMerge,
            // punchInOut,
            setPunch,
            setController,
            // punchInOut,
            // clinic location  switch---------//
            getClinicLocationList,
            logout

        }
    }
}

export default useHeader