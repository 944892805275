import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { InputLabel } from '@mui/material'


export default function index({ maxDate, SelectedDate, handleDateChange, Label, name, variant, className, size, isRequired, minDate, views, error, readOnly, Placeholder }) {
    return (
        <>
            <div className={`${className} mt-3`}>
                <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px", color: "#000", fontWeight: "600" }}>{Label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        readOnly={readOnly}
                        maxDate={maxDate}
                        views={views}
                        minDate={minDate}
                        inputFormat={Placeholder ? Placeholder : "MM/dd/yyyy"}
                        value={SelectedDate}
                        onChange={handleDateChange}
                        onBlur={handleDateChange}
                        name={name}
                        size={size}
                        // renderInput={(params) => <TextField variant={variant ? variant : "standard"}  {...params}

                        // />}
                    />
                </LocalizationProvider>
                {error && <div className="font-13" style={{ color: 'red' }}>{error}</div>}
            </div>
        </>
    )
}