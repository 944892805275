import React, { useState, Suspense, createContext } from 'react';
import './App.css';
import './fonts.css';
import './colors.css';
import "./Accordion.css";
import AllRoutes from './routes/AllRoutes';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const NotificationsState = createContext()
function App() {
  const [notificationcount, setNotificationcount] = useState(0)
  const [socket, setSocket] = useState(null)
  const [perioData, setPerioData] = useState({})
  const [missTeeth, setMissTeeth] = useState([])
  const [value, setValue] = useState(null)


  return (
    <>
      <ToastContainer />
      <Suspense fallback={"Loading..."}>
        <div className="App">
          <NotificationsState.Provider value={{ setNotificationcount, notificationcount, socket, setSocket,setPerioData,perioData,missTeeth, setMissTeeth,value, setValue }}>
            <AllRoutes />
          </NotificationsState.Provider>
        </div>
      </Suspense>
    </>
  );
}

export default App;
