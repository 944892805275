// index.js

import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavigationLayout from '../Components/Layout/NavigationLayout';

import ProcedureList from '../Pages/Setup/ProcedureCodes/ProcedureList';
import Category from '../Pages/Setup/ProcedureCodes/Category';
import TemplateLibrary from '../Pages/Setup/Template Library/template-library/templateLibrary';
import TemplateLibraryList from '../Pages/Setup/Template Library/template-library/templateLibrary-List';
import TemplateCategory from '../Pages/Setup/Template Library/template-library/templateCategory';
import Policies from '../Pages/Setup/Policies/Policies';
import useReduxStore from '../hooks/useReduxStore';
import PerioChart from '../Pages/Charting/PerioChart';
import ClinicLocations from '../Pages/Credentialing/clinicLocations';
import PatientNavigationLayout from '../Components/Layout/Patient Layout/patientNavigationLayout';
import Page404 from '../Components/common-components/Page404';


// Credentialing
const Login = lazy(() => import("../Pages/Credentialing/Login"))
const NewLogin = lazy(() => import("../Pages/Credentialing/newLogin"))
// const Dashboard = lazy(() => import("../Pages/Dashboard"))
const Billing = lazy(() => import("../Pages/Billing"))
const Clinic = lazy(() => import("../Pages/Clinic"))
const Forgot = lazy(() => import('../Pages/Credentialing/forgot-password'))
const Verification = lazy(() => import('../Pages/Credentialing/verification'))
const Verifyotp = lazy(() => import('../Pages/Credentialing/VerifyOTP'))
const VerificationTowfactor = lazy(() => import('../Pages/Credentialing/verificationTowfactor'))


const ResetPassword = lazy(() => import('../Pages/Credentialing/Reset-password'))
const TwoFactor = lazy(() => import('../Pages/Credentialing/twoFactorAuthentication'))

// Accept Invitation
const CreatePassword = lazy(() => import('../Pages/AcceptInvitation/CreatePassword'))


//---------------------- Side nav -----------------------
const AppointmentScheduler = lazy(() => import("../Pages/Appointment Scheduler/Index"))
const PatientManagement = lazy(() => import("../Pages/PatientManagement"))
const Charting = lazy(() => import("../Pages/Charting"))
const TreatmentDetails = lazy(() => import("../Pages/Charting/TreatmentDetails"))
const TreatmentPlans = lazy(() => import("../Pages/TreatmentPlans"))
const ProgressNotes = lazy(() => import("../Pages/ProgressNotes"))

const Accounts = lazy(() => import("../Pages/Accounts"))
const Inbox = lazy(() => import("../Pages/Chat/Inbox"))
const Notes = lazy(() => import("../Pages/Setup/Notes/Notes"))

const NewChat = lazy(() => import("../Pages/Chat/StartNewChat"))
const NewChannel = lazy(() => import("../Pages/Chat/NewChannel"))
const NewBroadcast = lazy(() => import("../Pages/Chat/NewBroadcast"))


//---------------------- Header Bar ----------------------


const TeamManagement = lazy(() => import('../Pages/team-management'))
const ClinicLocation = lazy(() => import('../Pages/Clinic/ClinicLocation'))

// Setup 
const ProcedureCodes = lazy(() => import('../Pages/Setup/ProcedureCodes'))
const MedicalHistory = lazy(() => import('../Pages/Setup/MedicalHistory/medicalHistory'))
const MedicalCategory = lazy(() => import('../Pages/Setup/MedicalHistory/category'))
const Medical = lazy(() => import('../Pages/Setup/MedicalHistory/index'))
const DiscountPlan = lazy(() => import('../Pages/Setup/Discount Plan/discountPlan'))
const Pharmacies = lazy(() => import('../Pages/Setup/Pharmacies/pharmacies'))
const MedicationGenric = lazy(() => import('../Pages/Setup/Medication/generic'))
const Medication = lazy(() => import('../Pages/Setup/Medication/index'))
const MedicationBrand = lazy(() => import('../Pages/Setup/Medication/brand'))
const Diagnosis = lazy(() => import('../Pages/Setup/Diagnosis/index'))
const AppointmentTypes = lazy(() => import('../Pages/Setup/Appointment Type/appointmentType'))

const Reminder = lazy(() => import('../Pages/Setup/Reminders/index'))
const Provider = lazy(() => import('../Pages/Setup/Reminders/provider'))
const Patient = lazy(() => import('../Pages/Setup/Reminders/patient'))
const RecallTypes = lazy(() => import('../Pages/Setup/Recall Type/recallType'))
const RolesPermission = lazy(() => import('../Pages/Setup/Role & Permissions/rolePermissions'))

const Scheduler = lazy(() => import('../Pages/Setup/Scheduler'))
const Operatories = lazy(() => import('../Pages/Setup/Scheduler/operatories'))
const BlockOut = lazy(() => import('../Pages/Setup/Scheduler/blockOut'))
const ReferralSlip = lazy(() => import('../Pages/Setup/Referral Slip/referralSlip'))
const AdjustmentType = lazy(() => import('../Pages/Setup/Adjustment Types/adjustmentType'))
const Employers = lazy(() => import('../Pages/Setup/Employers/employers'))
const BillingType = lazy(() => import('../Pages/Setup/Billing Type/billingType'))

const InsurancePlan = lazy(() => import('../Pages/Setup/Insurance Plan'))
const InsuranceDetails = lazy(() => import('../Pages/Setup/Insurance Plan/insuranceDetails'))
const InsuranceCategory = lazy(() => import('../Pages/Setup/Insurance Plan/insuranceCategory'))
const FeeSchedule = lazy(() => import('../Pages/Setup/FeeSchedule/FeeSchedule'))
const Security = lazy(() => import('../Pages/Setup/Security/Security'))
const WaitingList = lazy(() => import('../Pages/Appointment Scheduler/PatientWaitingList'))
const AuditTrails = lazy(() => import('../Pages/AuditTrails'))
const Macros = lazy(() => import('../Pages/Setup/Macros/macros'))
const PaymentGateway = lazy(() => import('../Pages/Setup/PaymentGateway'))

const PunchInOut = lazy(() => import('../Pages/Punch-In-Out/index'))
const Attendance = lazy(() => import('../Pages/Punch-In-Out/Attendance'))
const Regularization = lazy(() => import('../Pages/Punch-In-Out/Regularization'))

const Queries = lazy(() => import('../Pages/Queries'))
const Clinics = lazy(() => import('../Pages/Queries/Clinics'))
const Providers = lazy(() => import('../Pages/Queries/Providers'))
const PerioGraph = lazy(() => import('../Pages/Graphical Charting/perio_graph'))
const DrawOnCharting = lazy(() => import('../Pages/Charting/DrawOnCharting'))

// Notification
const NotificationPage = lazy(() => import('../Pages/Notification/Notification'))


// Patient Section
const AppointmentList = lazy(() => import('../PatientPage/Appointments/appointment-list'))
const TreatmentPlan = lazy(() => import('../PatientPage/Treatment Plan/treatmentPlan'))
const FinancialManagement = lazy(() => import('../PatientPage/Financial Management/financialManagement'))
const Dashboard = lazy(() => import("../PatientPage/Dashboard/dashboard"))
const Setting = lazy(() => import("../PatientPage/Settings/setting"))
const Form = lazy(() => import("../PatientPage/Forms/form"))
const Prescription = lazy(() => import("../PatientPage/Prescription/prescription"))
const TicketEasy = lazy(() => import("../PatientPage/Ticket Easy/ticketEasy"))

const PatientProfile = lazy(() => import("../PatientPage/Profile/index"))
const AddEditMember = lazy(() => import("../PatientPage/Profile/AddEditMember"))
const AddEditInsurance = lazy(() => import("../PatientPage/Profile/AddEditInsurance"))

const ConsentForm = lazy(() => import("../PatientPage/ConsentForm/ConsentForm"))

const Eprescription = lazy(() => import("../Pages/Charting/Eprescription"))
const PatientNote = lazy(() => import("../Pages/Charting/PatientNote"))

const Print = lazy(() => import("../Components/common-components/PrintPage/Print"))


const AllRoutes = () => {
    const { selector: user } = useReduxStore("auth")
    return (
        <Routes>
            {/* Credentialing */}
            <Route path="/auth/create-member-account-password/:token" element={<CreatePassword />} />
            {
                !user?.token ? <>
                    <Route path="/" element={<Navigate to="auth/new-login" />} />
                    <Route path="auth/new-login" element={<NewLogin />} />
                    <Route path="auth/login" element={<Login />} />
                    <Route path="auth/forgot" element={<Forgot />} />
                    <Route path="auth/verification" element={<Verification />} />
                    <Route path="auth/verification-tow-factor" element={<VerificationTowfactor />} />

                    <Route path="auth/verify" element={<Verifyotp />} />
                    <Route path="auth/reset" element={<ResetPassword />} />
                    <Route path="auth/create-password" element={<ResetPassword />} />
                    <Route path="auth/two-factor" element={<TwoFactor />} />
                    <Route path="accept-invitation/:token" element={<CreatePassword />} />
                    <Route path="auth/clinic-select" element={<ClinicLocations />} />

                </>
                    :
                    <Route path='/' element={user?.roleName === "PATIENT" ? <PatientNavigationLayout /> : <NavigationLayout />} >
                        <Route path="/" element={<Navigate replace to={'/appointment_scheduler'} />} />
                        {/* <Route path='dashboard' element={<Dashboard />} /> */}
                        <Route path='clinic' element={<Clinic />} />
                        {/* <Route path='patient' element={<AddPatient />} /> */}
                        <Route path='billing' element={<Billing />} />

                        {/* Appointment */}
                        <Route path='appointment_scheduler' element={<AppointmentScheduler />} />


                        {/* PatientManagement */}
                        <Route path='patient-management' element={<PatientManagement />} />

                        {/* Chat */}
                        <Route path='inbox' element={<Inbox />} />
                        <Route path='inbox/new_chat' element={<NewChat />} />
                        <Route path='inbox/new_channel' element={<NewChannel />} />
                        <Route path='inbox/new_broadcast' element={<NewBroadcast />} />


                        {/* Charting */}
                        <Route path='charting' element={<Charting />} />
                        <Route path='charting/treatment_details/:id' element={<TreatmentDetails />} />
                        <Route path='perio-chart' element={<PerioChart />} />
                        <Route path='perio-graph' element={<PerioGraph />} />
                        <Route path='draw' element={<DrawOnCharting />} />
                        <Route path='e-prescription' element={<Eprescription />} />
                        <Route path='patient-notes' element={<PatientNote />} />


                        {/* TreatmentPlans */}
                        <Route path='plans' element={<TreatmentPlans />} />


                        {/* Progress Notes */}
                        <Route path='progress-notes' element={<ProgressNotes />} />

                        {/* Accounts */}
                        <Route path='accounts' element={<Accounts />} />


                        {/* **********************  Setup Start  ********************** */}
                        {/* Procedure Codes */}
                        <Route path='procedure_list' element={<ProcedureCodes />} >
                            <Route index element={<ProcedureList />} />
                            <Route path='category' element={<Category />} />
                        </Route>

                        {/* Medical History */}
                        <Route path='medical_history' element={<Medical />}>
                            <Route index element={<MedicalHistory />} />
                            <Route path='category' element={<MedicalCategory />} />
                            <Route path='draft' element={<MedicalHistory />} />
                        </Route>

                        {/* Discount Plan */}
                        <Route path='discount_plan' element={< DiscountPlan />} />

                        {/* Pharmacies */}
                        <Route path='pharmacies' element={< Pharmacies />} />
                        <Route path='notes_types' element={< Notes />} />

                        {/* Medication */}
                        <Route path='medications' element={<Medication />}>
                            <Route index element={<MedicationGenric />} />
                            <Route path='brand' element={<MedicationBrand />} />
                        </Route>
                        {/* team-management */}
                        <Route path='team-management' element={<TeamManagement />} />

                        {/* ClinicLocation */}
                        <Route path='clinic-location' element={<ClinicLocation />} />
                        {/* Diagnosis */}
                        <Route path='diagnosis' element={< Diagnosis />} />

                        {/* Appointment_types */}
                        <Route path='appointment_types' element={< AppointmentTypes />} />

                        {/* Reminders */}
                        <Route path='reminders' element={<Reminder />}>
                            <Route index element={<Provider />} />
                            <Route path='patient' element={<Patient />} />
                        </Route>

                        {/* Recall Types */}
                        <Route path='recall_types' element={<RecallTypes />} />

                        {/* Roles & Permission */}
                        <Route path='roles-&-permission' element={<RolesPermission />} />

                        {/* Scheduler */}
                        <Route path='scheduler' element={<Scheduler />}>
                            <Route index element={<Operatories />} />
                            <Route path='blockout' element={<BlockOut />} />
                        </Route>

                        {/* Referral Slip */}
                        <Route path='referral_slip' element={<ReferralSlip />} />

                        {/* Adjustment Type */}
                        <Route path='adjustment_type' element={<AdjustmentType />} />

                        {/* Employers */}
                        <Route path='employers' element={<Employers />} />

                        {/* Billing Type */}
                        <Route path='billing_type' element={<BillingType />} />

                        {/* Insurance Plan */}
                        <Route path='insurance_plan' element={<InsurancePlan />}>
                            <Route index element={<InsuranceDetails />} />
                            <Route path='category' element={<InsuranceCategory />} />
                        </Route>

                        {/* feeSchedule */}
                        <Route path='fee_schedule' element={<FeeSchedule />} />

                        {/* template routes  */}
                        <Route path="template_library" element={<TemplateLibrary />} >
                            <Route index element={<TemplateLibraryList />} />
                            <Route path='template_category' element={< TemplateCategory />} />
                            <Route path='drafts' element={<TemplateLibraryList />} />
                        </Route>

                        <Route path='security' element={<Security />} />
                        <Route path='polices' element={<Policies />} />

                        <Route path='waiting_list' element={<WaitingList />} />
                        <Route path='payment-gate-way' element={<PaymentGateway />} />



                        {/* **********************  Setup End  ********************** */}

                        {/* Pharmacies */}
                        <Route path='pharmacies' element={< Pharmacies />} />
                        <Route path='notes_types' element={< Notes />} />

                        {/* Medication */}
                        <Route path='medications' element={<Medication />}>
                            <Route index element={<MedicationGenric />} />
                            <Route path='brand' element={<MedicationBrand />} />
                        </Route>
                        {/* team-management */}
                        <Route path='team-management' element={<TeamManagement />} />

                        {/* ClinicLocation */}
                        <Route path='clinic-location' element={<ClinicLocation />} />
                        {/* Diagnosis */}
                        <Route path='diagnosis' element={< Diagnosis />} />

                        {/* Appointment_types */}
                        <Route path='appointment_types' element={< AppointmentTypes />} />

                        {/* Reminders */}
                        <Route path='reminders' element={<Reminder />}>
                            <Route index element={<Provider />} />
                            <Route path='patient' element={<Patient />} />
                        </Route>

                        {/* Recall Types */}
                        <Route path='recall_types' element={<RecallTypes />} />

                        {/* Roles & Permission */}
                        <Route path='roles-&-permission' element={<RolesPermission />} />

                        {/* Scheduler */}
                        <Route path='scheduler' element={<Scheduler />}>
                            <Route index element={<Operatories />} />
                            <Route path='blockout' element={<BlockOut />} />
                        </Route>

                        {/* Referral Slip */}
                        <Route path='referral_slip' element={<ReferralSlip />} />

                        {/* Adjustment Type */}
                        <Route path='adjustment_type' element={<AdjustmentType />} />

                        {/* Employers */}
                        <Route path='employers' element={<Employers />} />

                        {/* Billing Type */}
                        <Route path='billing_type' element={<BillingType />} />

                        {/* Insurance Plan */}
                        <Route path='insurance_plan' element={<InsurancePlan />}>
                            <Route index element={<InsuranceDetails />} />
                            <Route path='category' element={<InsuranceCategory />} />
                        </Route>

                        {/* feeSchedule */}
                        <Route path='fee_schedule' element={<FeeSchedule />} />

                        {/* template routes  */}
                        <Route path="template_library" element={<TemplateLibrary />} >
                            <Route index element={<TemplateLibraryList />} />
                            <Route path='template_category' element={< TemplateCategory />} />
                            <Route path='drafts' element={<TemplateLibraryList />} />
                        </Route>

                        <Route path='security' element={<Security />} />
                        <Route path='polices' element={<Policies />} />

                        <Route path='waiting_list' element={<WaitingList />} />
                        <Route path='macros' element={<Macros />} />


                        {/* AuditTrails */}
                        <Route path='audit-trails' element={<AuditTrails />} />

                        {/* **********************  Setup End  ********************** */}


                        {/* punch in out */}
                        <Route path='punch_in_out' element={<PunchInOut />}>
                            <Route index element={<Attendance />} />
                            <Route path='regularization' element={<Regularization />} />
                        </Route>

                        {/* Query */}
                        <Route path='queries' element={<Queries />}>
                            <Route index element={<Clinics />} />
                            <Route path='providers' element={<Providers />} />
                        </Route>

                        <Route path='notification' element={<NotificationPage />} />


                        {/* ****************** Patient Routes ************************ */}

                        {/* Dashboard */}
                        <Route path='dashboard' element={<Dashboard />} />


                        {/* Appointment */}
                        <Route path='appointment-list' element={<AppointmentList />} />

                        {/* Treatment Plan */}
                        <Route path='treatment-plan' element={<TreatmentPlan />} />

                        {/* Financial Management */}
                        <Route path='financial-management' element={<FinancialManagement />} />

                        {/* Setting */}
                        <Route path='setting' element={<Setting />} />

                        {/* Form */}
                        <Route path='form' element={<Form />} />

                        <Route path='consent-form' element={<ConsentForm />} />



                        {/* patient profile */}
                        <Route path='profile' element={<PatientProfile />} />
                        <Route path='profile/:id' element={<AddEditMember />} />
                        <Route path='add-member' element={<AddEditMember />} />
                        <Route path='add-insurance' element={<AddEditInsurance />} />
                        <Route path='edit-insurance/:id' element={<AddEditInsurance />} />
                        <Route path='insurance-details/:id' element={<AddEditInsurance />} />
                        {/* Prescription */}
                        <Route path='prescription' element={<Prescription />} />

                        {/* Ticket Easy */}
                        <Route path='ticket-easy' element={<TicketEasy />} />

                        <Route path='print/:type/:id' element={<Print />} />

                    </Route>
            }
            <Route path="*" element={<Page404 />} />
        </Routes >
    );
}


export default AllRoutes;
